import { ReactNode } from 'react';
import { CmtModalButton } from '../../components/CmtModalButton';
import { ECSInstance } from '../../services/ecs.service';
import { EcsForm } from './ecs-form';

export function EcsFormButton(props: {
  data: ECSInstance | null;
  title: ReactNode;
  excludeProjectIds?: string[];
  organizationId: string;
  trigger: (actions: { show: () => void; hide: () => void }) => ReactNode;
  onSuccess?: (done: boolean) => void;
}) {
  return (
    <CmtModalButton title={props.title} trigger={props.trigger} width={500}>
      {({ hide }) => {
        return (
          <EcsForm
            organizationId={props.organizationId}
            excludeProjectIds={props.excludeProjectIds}
            onSuccess={(d) => {
              hide();
              props.onSuccess?.(d);
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
