import { Tooltip, Modal } from 'antd';
import { ReactNode } from 'react';
const { confirm } = Modal;

export function ConfirmButton(props: {
  title: string;
  content: string;
  children: (actions: { trigger: () => void }) => ReactNode;
  onOk: () => void;
}) {
  return (
    <Tooltip title={props.title}>
      {props.children({
        trigger: () => {
          confirm({
            title: props.title,
            content: props.content,
            centered: true,
            onOk: props.onOk,
          });
        },
      })}
    </Tooltip>
  );
}
