import { useRequest } from 'ahooks';
import { Service } from 'ahooks/lib/useRequest/src/types';
import { message, Popconfirm } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { ReactNode } from 'react';

export function CmtPopConfirmAction<T>(props: {
  data: T;
  service: Service<any, any>;
  onOk: (param: {
    data: T;
    run: (param: any) => any;
    loading: boolean;
  }) => void;
  title: ReactNode;
  okText?: string;
  icon?: React.ReactNode;
  cancelText?: string;
  errorMessage?: string;
  successMessage?: string;
  children: (param: { loading: boolean }) => ReactNode;
  onSuccess?: () => void;
  placement?: TooltipPlacement;
  disabled?: boolean;
}) {
  const loadingMessageKey = 'message-key';
  const [messageApi, contextHolder] = message.useMessage();
  const { run, loading } = useRequest(props.service, {
    manual: true,
    debounceWait: 200,
    onSuccess: (res) => {
      messageApi.open({
        type: 'success',
        key: loadingMessageKey,
        content: props.successMessage || '操作成功',
        duration: 2,
      });
      props.onSuccess?.();
    },
    onError: (err) => {
      messageApi.open({
        type: 'error',
        key: loadingMessageKey,
        content: (props.errorMessage || '操作失败') + '：' + err.message,
        duration: 5,
      });
    },
  });

  const trigger = () => {
    props.onOk({ data: props.data, run: run, loading: loading });
  };

  return (
    <>
      {contextHolder}
      <Popconfirm
        title={props.title || '确认'}
        onConfirm={trigger}
        okText={props.okText || '确定'}
        cancelText={props.cancelText || '取消'}
        placement={props.placement || 'left'}
        icon={props.icon}
        disabled={props.disabled}
      >
        {props.children({ loading })}
      </Popconfirm>
    </>
  );
}
