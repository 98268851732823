import { PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Alert, Button, Card, Form, Input, message, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { BlankWrapper } from '../../../components/BlankWrapper';
import {
  getAllSystemSettings,
  saveAllSystemSettings,
} from '../../../services/system-settings.service';

export function SystemParamsPage() {
  const [form] = Form.useForm<any>();

  const { loading: loading, run: loadParams } = useRequest(
    getAllSystemSettings,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        form.setFieldsValue({ settings: data });
      },
      onError(e, params) {
        message.error('保存失败：' + e.message);
      },
    }
  );

  const { loading: updating, run: runSave } = useRequest(
    saveAllSystemSettings,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('保存成功');
      },
      onError(e, params) {
        message.error('保存失败：' + e.message);
      },
    }
  );

  useEffect(() => {
    loadParams();
  }, []);
  return (
    <BlankWrapper>
      <Card title="系统参数设置" style={{ width: 800 }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            type="warning"
            message="请勿随意更改系统参数，除非你知道怎么做（参数值均已加密存储）。"
          ></Alert>
          <Spin spinning={updating || loading}>
            <Form
              disabled={updating}
              form={form}
              layout="horizontal"
              labelCol={{ span: 3 }}
              labelAlign={'left'}
              onFinish={() => {
                form.validateFields().then(() => {
                  runSave(form.getFieldValue('settings'));
                });
              }}
            >
              <Form.List name="settings">
                {(fields, { add, remove }) => (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {fields.map(({ key, name, ...resetFields }) => (
                      <Card size="small" key={key}>
                        <Form.Item
                          label="参数名"
                          {...resetFields}
                          name={[name, 'key']}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="参数值"
                          {...resetFields}
                          name={[name, 'value']}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="备注"
                          {...resetFields}
                          name={[name, 'remark']}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </Card>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Space>
                )}
              </Form.List>
              <Form.Item style={{ marginTop: 30 }}>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Space>
      </Card>
    </BlankWrapper>
  );
}
