import { UserOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import { Row, Col, Space, Card, Typography } from 'antd';
import { BlankWrapper } from '../../components/BlankWrapper';
import { TwoFactorAuthSetting } from './2fa-settings';
import { BasicInfoForm } from './basic-info-form';
import { PasswordForm } from './password-form';

export function UserProfilePage() {
  useTitle('个人设置', { restoreOnUnmount: true });
  return (
    <BlankWrapper
      breadcrumb={[{ name: '', icon: <UserOutlined /> }, { name: '个人设置' }]}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          paddingBottom: 30,
        }}
      >
        <Row justify="center">
          <Col>
            <Space direction="vertical" style={{ width: 800 }}>
              <Typography.Title level={3}>Hi, there!</Typography.Title>
              <Card title="基本信息">
                <BasicInfoForm></BasicInfoForm>
              </Card>
              <Card title="安全设置">
                <PasswordForm />
              </Card>
              <Card title="双重身份验证">
                <TwoFactorAuthSetting />
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </BlankWrapper>
  );
}
