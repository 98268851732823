import { SyncOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Typography,
  Space,
  Input,
  Button,
  Select,
  Form,
  Popover,
  Result,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { ConvertDate, HumanizeDate } from '../../common/utils/convert-date';
import { BlankWrapper } from '../../components/BlankWrapper';
import { CmtTable } from '../../components/CmtTable';
import { Project, queryProjectApi } from '../../services/project.service';
import { ConfirmButton } from '../../components/ConfirmButton/confirm-button';
import { InfiniteSelect } from '../../components/InfiniteSelect';
import {
  deletePlanApi,
  getPlanTypeName,
  Plan,
  PlanType,
  PlanTypeOptions,
  queryPlansApi,
} from '../../services/plan.service';
import { PlanParamView } from '../../components/PlanParamView';
import { PlanCronView } from './plan-cron-view';
import {
  Organization,
  queryOrganizationApi,
} from '../../services/organization.service';

const { Text, Paragraph } = Typography;

export function PlanPage() {
  useTitle('自动化计划');
  const tableRef = useRef<any>(null);
  const [selectedOrder, setSelectedOrder] = useState<Plan | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const planTypesOptions = [{ value: '', label: '全部' }, ...PlanTypeOptions];

  const planStatusOptions = [
    { value: null, label: '全部' },
    { value: true, label: '启用中' },
    { value: false, label: '已停用' },
  ];

  const columns: ColumnsType<Plan> = [
    {
      title: '项目',
      dataIndex: 'projectName',
      width: 100,
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '任务内容',
      dataIndex: 'actionName',
      render: (value, record, index) => {
        return (
          <Space>
            <Text> {value}</Text>
            {record.payload && (
              <Popover
                content={
                  <PlanParamView
                    actionId={record.actionId}
                    projectId={record.projectId}
                    payload={record.payload}
                  />
                }
                title={`【${record.name}】参数`}
                trigger="hover"
                placement="right"
              >
                <Button
                  shape="round"
                  size={'small'}
                  onClick={() => setSelectedOrder(record)}
                >
                  参数
                </Button>
              </Popover>
            )}
          </Space>
        );
      },
    },
    {
      title: '类型',
      width: 160,
      dataIndex: 'type',
      render: (value, record, index) => {
        return (
          <>
            {getPlanTypeName(value)}
            {record.type === PlanType.Cron && (
              <PlanCronView
                active={record.active}
                planId={record.id}
                cron={record.cron}
              />
            )}
          </>
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'active',
      width: 80,
      render(value, record, index) {
        return value ? '启用中' : '已停用';
      },
    },

    {
      title: '创建日期',
      dataIndex: 'createdAt',
      width: 150,
      render(value) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      width: 150,
      render(value) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
  ];

  return (
    <BlankWrapper>
      <CmtTable
        ref={tableRef}
        title="自动化计划"
        description="单次或周期执行的计划"
        rowKey="id"
        colums={columns}
        api={queryPlansApi}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item hidden name="internal" initialValue={'false'}>
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  name={'organizationId'}
                  label={'组织'}
                  initialValue={''}
                >
                  <InfiniteSelect
                    defaultOptions={[{ label: '全部', value: '' }]}
                    style={{ width: 120 }}
                    api={queryOrganizationApi}
                    onChange={(v) => {
                      setSelectedOrg(v);
                      submit();
                    }}
                    option={(item: Project) => {
                      return { label: item.name, value: item.id };
                    }}
                  />
                </Form.Item>
                <Form.Item name={'projectId'} label={'项目'} initialValue={''}>
                  <InfiniteSelect
                    defaultParams={{ organizationId: selectedOrg }}
                    defaultOptions={[{ label: '全部', value: '' }]}
                    style={{ width: 120 }}
                    api={queryProjectApi}
                    onChange={submit}
                    option={(item: Project) => {
                      return { label: item.name, value: item.id };
                    }}
                  />
                </Form.Item>
                <Form.Item name={'type'} label={'类型'} initialValue={''}>
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={planTypesOptions}
                  ></Select>
                </Form.Item>
                <Form.Item name={'active'} label={'状态'} initialValue={null}>
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={planStatusOptions}
                  ></Select>
                </Form.Item>
                <Form.Item name={'name'} label={'名称'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入计划名称"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
