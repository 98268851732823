import {
  DollarCircleOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Select, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { BlankWrapper } from '../../components/BlankWrapper';
import { ColumnsType } from 'antd/es/table';
import { useRequest, useTitle } from 'ahooks';
import { ConvertDate } from '../../common/utils/convert-date';
import { CmtTable } from '../../components/CmtTable';
import {
  ECSInstance,
  EcsRegionIds,
  EcsStatus,
  queryEcsApi,
} from '../../services/ecs.service';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EcsFormButton } from './create-ecs-button';
import { RemoveEcsButton } from './remove-ecs-button';
import { UpgradeCmtButton } from './upgrade-cmt-button';
import { queryProjectApi } from '../../services/project.service';

export function EcsManagementPage() {
  useTitle('执行器管理', { restoreOnUnmount: true });
  const tableRef = useRef<any>(null);
  const [existProjectIds, setExistProjectIds] = useState<string[]>([]);
  const params = useParams();
  const { data: projects, run: loadProjects } = useRequest(queryProjectApi, {
    manual: true,
    debounceWait: 300,
  });

  useEffect(() => {
    if (params.id) {
      loadProjects({ pageSize: 100, current: 1, organizationId: params.id });
    }
  }, [params.id]);
  const columns: ColumnsType<ECSInstance> = [
    {
      title: '实例ID',
      dataIndex: 'instanceId',
      key: 'instanceId',
    },
    {
      title: '实例名称',
      dataIndex: 'instanceName',
      key: 'instanceName',
      render(value) {
        return <Typography.Text copyable>{value}</Typography.Text>;
      },
    },
    {
      title: '所属项目',
      dataIndex: 'projectId',
      key: 'projectId',
      render(value, record, index) {
        return projects?.list.find((x) => x.id === value)?.name;
      },
    },
    {
      title: 'IP',
      dataIndex: 'ipaddress',
      key: 'ipaddress',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render(value, record, index) {
        return EcsStatus.find((x) => x.value === value)?.label;
      },
    },
    {
      title: '区域',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(value, record, index) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: 80,
      render(value, record, index) {
        return (
          <>
            <UpgradeCmtButton
              data={record}
              onSuccess={() => tableRef.current.refresh()}
            ></UpgradeCmtButton>
            <RemoveEcsButton
              data={record}
              onSuccess={() => tableRef.current.refresh()}
            ></RemoveEcsButton>
          </>
        );
      },
    },
  ];
  return (
    <BlankWrapper>
      <CmtTable
        ref={tableRef}
        title="执行器"
        description="管理阿里云服务器实例管理，仅支持在广州区域创建按量实例， 请为每个项目创建一个执行器，否则无法执行对应项目的任务"
        rowKey="instanceId"
        api={queryEcsApi}
        colums={columns}
        onDataLoad={(data) => {
          const ids: string[] = data.list.map((x: any) => x.projectId);
          setExistProjectIds(ids);
        }}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  hidden
                  name="organizationId"
                  initialValue={params.id}
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  name="regionId"
                  label="区域"
                  initialValue={'cn-guangzhou'}
                >
                  <Select
                    options={EcsRegionIds}
                    onChange={submit}
                    style={{ width: 120 }}
                  ></Select>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
              {params.id && (
                <EcsFormButton
                  title="添加执行器"
                  organizationId={params.id || ''}
                  excludeProjectIds={existProjectIds}
                  onSuccess={(done) => {
                    if (done) {
                      tableRef.current.refresh();
                    }
                  }}
                  trigger={({ show }) => {
                    return (
                      <Button type="primary" onClick={show}>
                        <PlusOutlined /> 添加执行器
                      </Button>
                    );
                  }}
                  data={null}
                ></EcsFormButton>
              )}
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
