import httpClient from './http-client';

export async function getAllSystemSettings() {
  const res = await httpClient.get('/system-settings');
  return res;
}

export async function saveAllSystemSettings(params: any[]) {
  const res = await httpClient.post('/system-settings', params);
  return res;
}
