import { useMemo, useState } from 'react';

export function useFeatureValue<T>(group: string, setting: string) {
  const [featureValue, setFeatureValue] = useState<T | undefined>();
  useMemo(() => {
    const flags: { [k: string]: any } = JSON.parse(setting || '{}');
    if (!flags) {
      return;
    }
    const groups = Object.keys(flags);
    if (groups.length && groups.includes(group)) {
      const features = flags[group];
      setFeatureValue(features);
    }
  }, [setting]);
  return featureValue;
}
