import { Card, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import {
  OpenIdAuthValue,
  ProjectAuthType,
} from '../../../services/project.service';

export function AuthValueInput(props: {
  type: ProjectAuthType;
  value?: string;
  onChange?: (val: string) => void;
}) {
  const [type, setType] = useState(props.type);
  const [openIdValue, setOpenIdValue] = useState<OpenIdAuthValue>({
    openId: '',
    appKey: '',
    appSecret: '',
  });
  useEffect(() => {
    setType(props.type);
    // if (props.type === ProjectAuthType.OpenId && props.value) {
    //   try {
    //     const val = JSON.parse(props.value);
    //     if (typeof val === 'object') {
    //       setOpenValue(val);
    //     }
    //   } catch (error) {}
    // }
  }, [props.type]);

  useEffect(() => {
    if (openIdValue.appKey && openIdValue.appSecret && openIdValue.openId) {
      const val = JSON.stringify({ type: props.type, value: openIdValue });
      props.onChange?.(val);
    } else {
      props.onChange?.('');
    }
  }, [openIdValue]);
  if (type === ProjectAuthType.OpenId) {
    return (
      <Card>
        <Form.Item label="App Key" labelCol={{ span: 3 }}>
          <Input
            onChange={(v) => {
              setOpenIdValue({ ...openIdValue, appKey: v.target.value.trim() });
            }}
          ></Input>
        </Form.Item>
        <Form.Item label="App Secret" labelCol={{ span: 3 }}>
          <Input
            onChange={(v) => {
              setOpenIdValue({
                ...openIdValue,
                appSecret: v.target.value.trim(),
              });
            }}
          ></Input>
        </Form.Item>
        <Form.Item label="Open Id" labelCol={{ span: 3 }}>
          <Input
            onChange={(v) => {
              setOpenIdValue({ ...openIdValue, openId: v.target.value.trim() });
            }}
          ></Input>
        </Form.Item>
      </Card>
    );
  }
  return (
    <TextArea
      rows={5}
      onChange={(v) => {
        props.onChange?.(v.target.value);
      }}
    />
  );
}
