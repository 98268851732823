import {
  AppstoreOutlined,
  BankOutlined,
  BarsOutlined,
  DollarCircleOutlined,
  FormOutlined,
  PlusOutlined,
  SafetyCertificateOutlined,
  SafetyCertificateTwoTone,
  SafetyOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  SyncOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Space,
  Input,
  Select,
  Button,
  Form,
  Typography,
  Tooltip,
  Avatar,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useContext, useRef } from 'react';
import { ConvertDate } from '../../../common/utils/convert-date';
import { BlankWrapper } from '../../../components/BlankWrapper';
import { CmtTable } from '../../../components/CmtTable';
import {
  getGpoUserStatusOptionName,
  GpoUserStatusOptions,
  queryUsersApi,
  UserDto,
} from '../../../services/user.service';
import { UserRoleInfo } from './user-role-info';
import { UserFormButton } from './create-user-button';
import { TwoFactorStatusIcon } from '../../../components/TwoFactorStatusIcon';
import { GlobalContext } from '../../../context';
import { JwtMetaItemKeys } from '../../../common/dtos/jwt-token-payload';

export function UserManagementPage() {
  useTitle('管理员管理', { restoreOnUnmount: true });
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const currentUserId = globalContext.userMetaData?.find(
    (x) => x.k === JwtMetaItemKeys.userId
  )?.v;
  const statusOptions = [{ value: '', label: '全部' }, ...GpoUserStatusOptions];
  const tableRef = useRef<any>(null);
  const columns: ColumnsType<UserDto> = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      render: (val, record) => {
        return (
          <Space>
            <Avatar>{val.split('')[0]}</Avatar>
            <Typography.Text>{val}</Typography.Text>
          </Space>
        );
      },
    },
    {
      title: '电话',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '角色组',
      dataIndex: 'role',
      key: 'role',
      render(value, record, index) {
        return <UserRoleInfo data={record} />;
      },
    },
    {
      title: '2FA',
      dataIndex: 'isTwoFactorAuthenticationEnabled',
      key: 'isTwoFactorAuthenticationEnabled',
      render(value, record, index) {
        return <TwoFactorStatusIcon active={value} />;
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render(value, record, index) {
        return getGpoUserStatusOptionName(value);
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(value, record, index) {
        return ConvertDate(value);
      },
    },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 60,
      key: 'actions',
      render(value, record, index) {
        return (
          <div>
            <UserFormButton
              title="编辑成员"
              onSuccess={(done) => {
                if (done) {
                  tableRef.current.refresh();
                }
              }}
              trigger={({ show }) => {
                return (
                  <Tooltip title={'编辑成员'}>
                    <Button
                      type="text"
                      size="small"
                      onClick={show}
                      disabled={record.id === currentUserId}
                    >
                      <FormOutlined />
                    </Button>
                  </Tooltip>
                );
              }}
              data={record}
            ></UserFormButton>
          </div>
        );
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '系统设置', icon: <SettingOutlined /> },
        { name: '成员管理' },
      ]}
    >
      <CmtTable
        ref={tableRef}
        title="组织成员"
        description="普通成员无法修改项目，及管理组织成员"
        rowKey="id"
        api={queryUsersApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item name="name" label="姓名" initialValue={''}>
                  <Input
                    placeholder="输入姓名"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'status'} label={'状态'} initialValue={''}>
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions}
                  ></Select>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
              <UserFormButton
                title="添加成员"
                onSuccess={(done) => {
                  if (done) {
                    tableRef.current.refresh();
                  }
                }}
                trigger={({ show }) => {
                  return (
                    <Button type="primary" onClick={show}>
                      <PlusOutlined /> 添加成员
                    </Button>
                  );
                }}
                data={null}
              ></UserFormButton>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
