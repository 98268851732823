import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';

export enum ProjectPlatforms {
  GPO = 'gpo',
  RJMart = 'rjmart',
  CAS = 'cas',
}

export enum ProjectAuthType {
  /**
   * AccessToken
   */
  AccessToken = 'AccessToken',
  /**
   * Cookie
   */
  Cookie = 'Cookie',
  /**
   * OpenId
   */
  OpenId = 'OpenId',
  /**
   * Credentials
   */
  Credentials = 'Credentials',
}

export interface OpenIdAuthValue {
  openId: string;
  appKey: string;
  appSecret: string;
}
export interface Project {
  id: string;
  organizationId: string;
  name: string;
  description: string;
  platform: ProjectPlatforms;
  authType: ProjectAuthType;
  authValue: string;
  hasAuthValue: boolean;
  dingdingWebhookUrl: string;
  weixinWebhookUrl: string;
  createdAt: string;
  updatedAt: string;
  logoUrl: string;
  metabaseSecretKey: string;
  metabasePayload: string;
  netmask: string;
  shareExecutorChannel?: string;
  jdSessionState?: string;
  accounts?: string;
}

export interface IpHistory {
  organizationId: string;
  projectId: string;
  netmask: string;
  ipAddress: string;
  tokeDate: number;
  releasedDate: number;
}

export async function queryProjectApi(
  params: PagingRequest
): Promise<PagingResponse<Project>> {
  const res: PagingResponse<Project> = await httpClient.get('/projects', {
    params,
  });
  const list1 = res.list.filter((x) => x.hasAuthValue);
  const list2 = res.list.filter((x) => !x.hasAuthValue);
  res.list = [...list1, ...list2];
  return res;
}

export async function queryIpHistoryApi(
  params: PagingRequest
): Promise<PagingResponse<IpHistory>> {
  const res: PagingResponse<IpHistory> = await httpClient.get(
    '/projects/ip-history',
    {
      params,
    }
  );
  return res;
}

export async function getSingleProjectApi(projectId: string): Promise<Project> {
  const res: any = await httpClient.get(`/projects/${projectId}`);
  return res;
}

export async function getSingleProjectMetabaseReportLinkApi(
  projectId: string
): Promise<string> {
  const res: any = await httpClient.get(`/projects/${projectId}/metabase-link`);
  return res;
}

export async function updateSingleProjectApi(
  projectId: string,
  params: any
): Promise<Project> {
  const res: any = await httpClient.patch(`/projects/${projectId}`, params);
  return res;
}

export async function deleteSingleProjectApi(
  projectId: string
): Promise<Project> {
  const res: any = await httpClient.delete(`/projects/${projectId}`);
  return res;
}

export async function createSingleProjectApi(
  createProjectDto: any
): Promise<Project> {
  const res: any = await httpClient.post(`/projects`, createProjectDto);
  return res;
}

export async function reviewFakeIp(projectId: string): Promise<Project> {
  const res: any = await httpClient.post(
    `/projects/${projectId}/create-new-ip-address`
  );
  return res;
}

export async function syncJDSessionToAllProjects(params: {
  organizationId: string;
  sourceProjectId: string;
}) {
  const res: any = await httpClient.post(`/projects/sync-jd-session-to-all`, {
    params,
  });
  return res;
}

export async function testGpoAccountApi(params: {
  projectId: string;
  account: string;
}): Promise<any> {
  const res: any = await httpClient.post(`/projects/test-gpo-login-account`, params);
  return res;
}
