import { useRequest } from 'ahooks';
import { Space, Spin } from 'antd';
import { useEffect } from 'react';
import { getAuthTokenOwner } from '../../../services/gpo/util.service';

export function ProjectAuthTokenInfo(props: {
  projectId: string;
  organizationId: string;
}) {
  const { loading, data, run } = useRequest(getAuthTokenOwner, {
    manual: true,
    debounceWait: 300,
  });
  useEffect(() => {
    if (props.projectId) {
      run(props.organizationId, props.projectId);
    }
  }, [props.projectId]);
  return loading ? (
    <Spin spinning={true}></Spin>
  ) : (
    <Space>
      {data
        ? `当前Token账号：${data.username}@${data.loginName}`
        : '身份Token用于操作智慧云，'}
    </Space>
  );
}
