import { Navigate, RouteObject } from 'react-router-dom';
import { AuditLogsPage } from './audit-logs';
import { CronManagementPage } from './cron';
import { EcsManagementPage } from '../ecs';
import { OrganizationProfilePage } from './settings';
import { UserManagementPage } from './users';
import { SystemParamsPage } from './system-params';

export const adminRouters: RouteObject[] = [
  {
    path: 'members',
    element: <UserManagementPage />,
  },
  {
    path: 'system-params',
    element: <SystemParamsPage />,
  },
  {
    path: 'crons',
    element: <CronManagementPage />,
  },
  {
    path: '',
    element: <Navigate to={'members'} replace />,
  },
];
