import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../components/CmtPopConfirmAction';
import { ECSInstance, removeEcsInstancesApi } from '../../services/ecs.service';

const { Text, Paragraph } = Typography;
export function RemoveEcsButton(props: {
  data: ECSInstance;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={removeEcsInstancesApi}
      title={
        <Space direction="vertical" style={{ maxWidth: 300 }}>
          <Text strong>{`确定移除吗？`}</Text>
          <Paragraph>{props.data.instanceName}</Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run({ instanceIds: [data.instanceId], regionId: data.region });
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button type="text" size="small" disabled={loading} loading={loading}>
          <DeleteOutlined />
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
