import axios, { AxiosInstance } from 'axios';
import { ENV_CONFIG } from '../env-config';
import { getAccessToken } from './auth.service';
import { redirect } from 'react-router-dom';

const httpClient: AxiosInstance = axios.create({
  baseURL: ENV_CONFIG.BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

httpClient.interceptors.request.use(
  function (config) {
    const token = getAccessToken();
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
httpClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (!error.response) {
      return Promise.reject({ status: -1, message: '无法访问系统服务' });
    }
    if (error.response.status === 401) {
      // TODO redirect to login
      window.location.href = '/login';
      return;
    }
    return Promise.reject(error.response.data);
  }
);

export default httpClient;
