import { useRequest } from 'ahooks';
import {
  message,
  Button,
  Input,
  Form,
  Typography,
  Spin,
  Alert,
  Space,
  Popconfirm,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import useMessage from 'antd/es/message/useMessage';
import { useEffect, useState } from 'react';
import { isElectron } from '../../../services/ipc.service';
import { getBrowserState } from '../../../services/native.service';
import {
  Project,
  syncJDSessionToAllProjects,
  updateSingleProjectApi,
} from '../../../services/project.service';
import ButtonGroup from 'antd/es/button/button-group';

export function NativeRequestButton(props: {
  onSuccess?: (data: string) => void;
}) {
  const [messageApi, context] = useMessage();
  const { loading, data, run } = useRequest(getBrowserState, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {
      props.onSuccess?.(data.data);
    },
    onError(e) {
      messageApi.error(e.message);
    },
  });
  return (
    <ButtonGroup>
      <Button
        type="text"
        size="small"
        loading={loading}
        onClick={() => {
          run('PC');
        }}
        disabled={!isElectron()}
      >
        {context}
        唤起PC版
      </Button>
      <Button
        type="text"
        size="small"
        loading={loading}
        onClick={() => {
          run('MOBILE');
        }}
        disabled={!isElectron()}
      >
        {context}
        唤起移动版
      </Button>
    </ButtonGroup>
  );
}

export function SessionSettingForm(props: { project: Project | undefined }) {
  const [electronEnv, setElectronEnv] = useState(false);
  const [form] = Form.useForm<any>();
  useEffect(() => {
    if (props.project) {
      form.setFieldsValue({
        jdSessionState: props.project.jdSessionState,
      });
    }
    setElectronEnv(isElectron());
  }, [props.project]);

  const { loading: updating, run: runUpdate } = useRequest(
    updateSingleProjectApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('设置保存成功');
        // props.onSuccess?.(params[1]);
      },
      onError(e, params) {
        message.error('设置保存失败：' + e.message);
      },
    }
  );

  const { loading: syncing, run: runSync } = useRequest(
    syncJDSessionToAllProjects,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('同步成功');
      },
      onError(e, params) {
        message.error('同步失败：' + e.message);
      },
    }
  );
  return (
    <Spin spinning={updating || syncing}>
      <Form
        disabled={updating || syncing}
        form={form}
        labelCol={{ span: 3 }}
        labelAlign={'left'}
        onFinish={() => {
          runUpdate(props.project?.id || '', form.getFieldsValue());
        }}
      >
        <Alert message={<p>此功能需要配合【超卖通客户端】使用</p>}></Alert>
        <br />
        <FormItem label="京东Session" name="jdSessionState">
          <Input
            addonAfter={
              <NativeRequestButton
                onSuccess={(data) => {
                  form.setFieldValue('jdSessionState', data);
                  message.success('Session已换新， 请保存');
                }}
              ></NativeRequestButton>
            }
          />
        </FormItem>
        <Form.Item>
          <Space>
            <Button htmlType="submit" loading={updating}>
              保存
            </Button>
            <Popconfirm
              title="确定同步吗？"
              description="同步前请先保存，仅同步当前组织下的项目"
              onConfirm={() => {
                runSync({
                  sourceProjectId: props.project?.id || '',
                  organizationId: props.project?.organizationId || '',
                });
              }}
            >
              <Button loading={syncing}>同步到所有项目</Button>
            </Popconfirm>
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  );
}
