import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';
import { UserDto } from './user.service';

export interface Organization {
  id: string;
  name: string;
  description: string;
  force2fa: boolean;
  createdAt: Date;
  updatedAt: Date;
  settings?: string;
}

export async function getOrganization(): Promise<any> {
  const res: any = await httpClient.get(`/organizations/mine`);
  return res;
}

export async function getSingleOrganization(id: string): Promise<any> {
  const res: any = await httpClient.get(`/organizations/${id}`);
  return res;
}

export async function getOrganizationSchema(): Promise<any> {
  const res: any = await httpClient.get(`/organizations/form-schema`);
  return res;
}

export async function createOrganization(payload: any): Promise<any> {
  const res: any = await httpClient.post(`/organizations`, payload);
  return res;
}

export async function queryOrganizationApi(
  params: PagingRequest
): Promise<PagingResponse<Organization>> {
  const res: any = await httpClient.get('/organizations', { params });
  return res;
}

export async function updateOrganization(
  id: string,
  params: {
    name?: string;
    descriptions?: string;
    settings?: string;
  }
): Promise<any> {
  const res: any = await httpClient.patch(`/organizations/${id}`, params);
  return res;
}

export async function deleteOrganization(id: string): Promise<any> {
  const res: any = await httpClient.delete(`/organizations/${id}`);
  return res;
}

export async function queryOrganizationMembersApi(
  params: PagingRequest
): Promise<PagingResponse<Organization>> {
  const res: any = await httpClient.get('/organizations/members', { params });
  return res;
}

export interface AuditLog {
  id: string;
  organizationId: string;
  projectId: string;
  projectName: string;
  userId: string;
  userName: string;
  resource: string;
  action: string;
  remark: string;
  createdAt: Date;
  resourceName: string;
  actionName: string;
}

export async function queryAuditLogsApi(
  params: PagingRequest
): Promise<PagingResponse<AuditLog>> {
  const res: PagingResponse<AuditLog> = await httpClient.get('/audit-logs', {
    params,
  });
  return res;
}

export interface AuditLogResource {
  id: string;
  name: string;
}

export interface AuditLogAction {
  id: string;
  action: string;
  resourceId: string;
  name: string;
}

export async function queryAuditLogResourceApi(
  params: PagingRequest
): Promise<PagingResponse<AuditLogResource>> {
  const res: PagingResponse<AuditLogResource> = await httpClient.get(
    '/audit-logs/resources'
  );
  return res;
}

export async function queryAuditLogActionsApi(
  params: PagingRequest
): Promise<PagingResponse<AuditLogResource>> {
  const res: PagingResponse<AuditLogResource> = await httpClient.get(
    '/audit-logs/actions',
    { params }
  );
  return res;
}
