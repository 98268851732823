import {
  AppstoreAddOutlined,
  DeploymentUnitOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Typography } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { SubLayout } from '../../layouts/sub-layout/sub-layout';
import { getSingleOrganization } from '../../services/organization.service';

export function OrganizationMgmtPage() {
  const params = useParams();
  const { data, run: pullOrg } = useRequest(getSingleOrganization, {
    manual: true,
    debounceWait: 300,
  });
  useEffect(() => {
    params.id && pullOrg(params.id || '');
  }, [params.id]);

  const projectNavItems: ItemType[] = [
    {
      label: <NavLink to="projects">项目管理</NavLink>,
      icon: <AppstoreAddOutlined />,
      key: 'projects',
    },

    {
      label: <NavLink to="executors">执行器管理</NavLink>,
      icon: <DeploymentUnitOutlined />,
      key: 'executors',
    },
    {
      label: <NavLink to="members">组织成员</NavLink>,
      icon: <UserOutlined />,
      key: 'members',
    },
    {
      label: <NavLink to="settings">功能配置</NavLink>,
      icon: <SettingOutlined />,
      key: 'settings',
    },
  ];
  return (
    <SubLayout
      menuItems={projectNavItems}
      menuAddOn={<Typography.Title level={5}>{data?.name}</Typography.Title>}
    ></SubLayout>
  );
}
