import { useRequest } from 'ahooks';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { InfiniteSelect } from '../../components/InfiniteSelect';
import {
  createEcsInstanceApi,
  ECSImage,
  EcsRegionIds,
  queryEcsImagesApi,
} from '../../services/ecs.service';
import { Project, queryProjectApi } from '../../services/project.service';

export function EcsForm(props: {
  organizationId: string;
  excludeProjectIds?: string[];
  onSuccess?: (done: boolean) => void;
}) {
  const instanceTypes = [
    {
      label: '经济型 e (2vCPU, 2GiB, ~￥0.123/时）',
      value: 'ecs.e-c1m1.large',
    },
    {
      label: '突发性能实例 t6（2vCPU, 1GiB, ~￥0.059/时）',
      value: 'ecs.t6-c2m1.large',
    },
    {
      label: '突发性能实例 t6（2vCPU, 2GiB, ~￥0.118/时）',
      value: 'ecs.t6-c1m1.large',
    },
    {
      label: '突发性能实例 t6（2vCPU, 4GiB, ~￥0.236/时）',
      value: 'ecs.t6-c1m2.large',
    },
  ];
  const { loading, data, run } = useRequest(createEcsInstanceApi, {
    debounceWait: 300,
    manual: true,
    onSuccess(data, params) {
      message.success('创建执行器成功');
      props.onSuccess?.(true);
    },
    onError(e, params) {
      message.error(e.message);
    },
  });
  const [form] = Form.useForm<any>();

  return (
    <Form
      form={form}
      disabled={loading}
      labelCol={{ span: 4 }}
      labelAlign={'left'}
      onFinish={() => {
        run(form.getFieldsValue());
      }}
    >
      <Typography.Paragraph type="secondary">
        目前仅支持广州区域创建ECS
      </Typography.Paragraph>
      <Form.Item
        hidden
        name="organizationId"
        initialValue={props.organizationId}
      >
        <Input></Input>
      </Form.Item>
      <FormItem label="项目" name={'projectId'} rules={[{ required: true }]}>
        <InfiniteSelect
          defaultParams={{ organizationId: props.organizationId }}
          style={{ width: '100%' }}
          api={queryProjectApi}
          option={(item: Project) => {
            return {
              label: item.name,
              value: item.id,
              disabled: props.excludeProjectIds?.includes(item.id),
            };
          }}
        />
      </FormItem>
      <Form.Item
        name="regionId"
        label="区域"
        rules={[{ required: true }]}
        initialValue={EcsRegionIds[0].value}
      >
        <Select options={EcsRegionIds}></Select>
      </Form.Item>
      <Form.Item label="实例类型">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="instanceType"
              rules={[{ required: true }]}
              initialValue={instanceTypes[0].value}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Select options={instanceTypes} onChange={(value)=> {
              form.setFieldsValue({'instanceType': value});
            }}></Select>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item name="imageId" label="镜像" rules={[{ required: true }]}>
        <InfiniteSelect
          option={(item: ECSImage) => {
            return {
              label: (
                <div>
                  <div>{item.imageName}</div>
                  <Typography.Text type="secondary">
                    {item.description}
                  </Typography.Text>
                  <div>
                    <Tag>{item.OSType}</Tag>
                    <Tag>{item.OSName}</Tag>
                  </div>
                </div>
              ),
              value: item.imageId,
            };
          }}
          api={queryEcsImagesApi}
        ></InfiniteSelect>
      </Form.Item>
      <FormItem label="名称" name={'instanceName'} rules={[{ required: true }]}>
        <Input maxLength={50} />
      </FormItem>
      <Form.Item>
        <Row justify={'space-between'}>
          <Col>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                disabled={loading}
              >
                创建实例
              </Button>
              <Button type={'text'} onClick={() => props.onSuccess?.(false)}>
                取消
              </Button>
            </Space>
          </Col>
          <Col>{/* <Button type="default">测试</Button> */}</Col>
        </Row>
      </Form.Item>
    </Form>
  );
}
