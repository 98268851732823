import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  SyncOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { Space, Input, Select, Button, Form, Typography } from 'antd';
import { ConvertDate } from '../../common/utils/convert-date';
import { BlankWrapper } from '../../components/BlankWrapper';
import { CmtTable } from '../../components/CmtTable';
import {
  EventType,
  EventTypeOptions,
  queryEventApi,
} from '../../services/task.service';

const { Text } = Typography;

export function EventList(props: { taskId: string }) {
  const typeOptions = [{ label: '全部', value: '' }, ...EventTypeOptions];
  const columns = [
    {
      title: '#',
      width: 50,
      dataIndex: 'type',
      key: 'type',
      render: (value: EventType) => {
        switch (value) {
          case EventType.Error:
            return (
              <Text type={'danger'}>
                <CloseCircleFilled />
              </Text>
            );
          case EventType.Warn:
            return (
              <Text type={'warning'}>
                <WarningFilled />
              </Text>
            );
          case EventType.Success:
            return (
              <Text type={'success'}>
                <CheckCircleFilled />
              </Text>
            );
          default:
            return (
              <Text style={{ color: '#1890ff' }}>
                <InfoCircleFilled />
              </Text>
            );
        }
      },
    },
    {
      title: '事件',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: '发生时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (value: string) => {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
  ];
  return (
    <BlankWrapper paddingSize={0}>
      <CmtTable
        title="事件列表"
        description="任务执行过程中的所有事件"
        rowKey="id"
        api={queryEventApi}
        colums={columns}
        pollingInterval={5000}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item name="taskId" initialValue={props.taskId} hidden>
                  <Input></Input>
                </Form.Item>
                <Form.Item name={'type'} label={'类型'} initialValue={''}>
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={typeOptions}
                  ></Select>
                </Form.Item>
                <Form.Item name={'message'} label={'关键字'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="关键字"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
