import { ReactNode } from 'react';
import { CmtModalButton } from '../../../components/CmtModalButton';
import { UserDto } from '../../../services/user.service';
import { UserForm } from './user-form';

export function UserFormButton(props: {
  data: UserDto | null;
  title: ReactNode;
  trigger: (actions: { show: () => void; hide: () => void }) => ReactNode;
  onSuccess?: (done: boolean) => void;
}) {
  return (
    <CmtModalButton title={props.title} trigger={props.trigger} width={500}>
      {({ hide }) => {
        return (
          <UserForm
            data={props.data}
            onSuccess={(d) => {
              hide();
              props.onSuccess?.(d);
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
