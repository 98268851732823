import { SyncOutlined } from '@ant-design/icons';
import { useTitle } from 'ahooks';
import { Space, Input, Select, Button, Form, Typography, Avatar } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ConvertDate } from '../../common/utils/convert-date';
import { BlankWrapper } from '../../components/BlankWrapper';
import { CmtTable } from '../../components/CmtTable';
import { TwoFactorStatusIcon } from '../../components/TwoFactorStatusIcon';
import { queryOrganizationMembersApi } from '../../services/organization.service';
import {
  getGpoUserStatusOptionName,
  GpoUserStatusOptions,
  queryUsersApi,
  UserDto,
} from '../../services/user.service';
import { UserRoleInfo } from './user-role-info';

export function OrganizationUserManagementPage() {
  useTitle('组织成员', { restoreOnUnmount: true });
  const params = useParams();
  const statusOptions = [{ value: '', label: '全部' }, ...GpoUserStatusOptions];
  const tableRef = useRef<any>(null);
  const columns: ColumnsType<UserDto> = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      render: (val, record) => {
        return (
          <Space>
            <Avatar>{val.split('')[0]}</Avatar>
            <Typography.Text>{val}</Typography.Text>
          </Space>
        );
      },
    },
    {
      title: '电话',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '角色组',
      dataIndex: 'role',
      key: 'role',
      render(value, record, index) {
        return <UserRoleInfo data={record} />;
      },
    },
    {
      title: '2FA',
      dataIndex: 'isTwoFactorAuthenticationEnabled',
      key: 'isTwoFactorAuthenticationEnabled',
      render(value, record, index) {
        return <TwoFactorStatusIcon active={value} />;
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render(value, record, index) {
        return getGpoUserStatusOptionName(value);
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(value, record, index) {
        return ConvertDate(value);
      },
    },
  ];
  return (
    <BlankWrapper>
      <CmtTable
        ref={tableRef}
        title="组织成员"
        description="普通成员无法修改项目，及管理组织成员"
        rowKey="id"
        api={queryOrganizationMembersApi}
        colums={columns}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  hidden
                  name="organizationId"
                  initialValue={params.id}
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item name="name" label="姓名" initialValue={''}>
                  <Input
                    placeholder="输入姓名"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
                <Form.Item name={'status'} label={'状态'} initialValue={''}>
                  <Select
                    style={{ width: 120 }}
                    onChange={submit}
                    options={statusOptions}
                  ></Select>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
