import { Tooltip, Modal, Button, ModalProps } from 'antd';
import React, { ReactNode, useState } from 'react';

export interface CmtModalButtonProps {
  title: ReactNode;
  width?: number | string;
  footer?: (actions: { show: () => void; hide: () => void }) => ReactNode;
  children: (actions: { show: () => void; hide: () => void }) => ReactNode;
  trigger: (actions: { show: () => void; hide: () => void }) => ReactNode;
  fullscreen?: boolean;
}

export function CmtModalButton(props: CmtModalButtonProps) {
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  let width = props.width || 800;
  let bodyStyle: React.CSSProperties = {
    width: '100%',
    maxHeight: 'calc(80vh)',
    overflow: 'auto',
  };
  let modelStyle: React.CSSProperties = { width: width };
  if (props.fullscreen) {
    width = '100vw';
    modelStyle = { top: 0, right: 0, bottom: 0, left: 0 };
    const footHeight = props.footer ? 53 : 0;
    bodyStyle = {
      width: '100%',
      height: `calc(100vh - 55px -${footHeight}px)`,
      overflow: 'auto',
    };
  }

  return (
    <>
      {props.trigger({ show, hide })}
      <Modal
        style={modelStyle}
        open={open}
        title={props.title}
        onCancel={hide}
        destroyOnClose={true}
        centered={true}
        maskClosable={false}
        width={width}
        footer={props.footer?.({ show, hide }) || null}
      >
        <div style={bodyStyle}>{props.children({ show, hide })}</div>
      </Modal>
    </>
  );
}
