import { Cascader } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import { ShowSearchType } from 'rc-cascader';
import { ReactNode } from 'react';

export interface CmtCascaderOption {
  value: any;
  label: ReactNode | string;
  children?: CmtCascaderOption[];
  isLeaf?: boolean;
}
export function CmtCascader(props: {
  data: CmtCascaderOption[];
  value?: any;
  loading?: boolean;
  onChange?: (val: any) => void;
  style?: React.CSSProperties;
  allowClear?: boolean;
  multiple?: boolean;
  showSearch?: ShowSearchType<DefaultOptionType>;
  showCheckedStrategy?: any;
}) {
  return (
    <Cascader
      style={props.style}
      options={props.data}
      onChange={props.onChange}
      placeholder=""
      loading={props.loading}
      value={props.value}
      allowClear={props.allowClear}
      showSearch={props.showSearch}
      multiple={props.multiple}
      maxTagCount="responsive"
      showCheckedStrategy={props.showCheckedStrategy}
    />
  );
}
