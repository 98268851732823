import { CloseCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message } from 'antd';
import { ConfirmButton } from '../../components/ConfirmButton/confirm-button';
import { stopTaskApi } from '../../services/action.service';
import { Task, TaskStatus } from '../../services/task.service';

export function StopTaskButton(props: { data: Task; onSuccess?: () => void }) {
  const record = props.data;
  const canStop = [
    TaskStatus.Draft,
    TaskStatus.InProgress,
    TaskStatus.Ready,
    TaskStatus.Paused,
  ].includes(record.status);
  const [messageApi, contextHolder] = message.useMessage();
  const loadingMessageKey = 'interrupt-message-key';
  const { run, loading } = useRequest(stopTaskApi, {
    manual: true,
    debounceWait: 200,
    onBefore: () => {
      messageApi.open({
        type: 'loading',
        key: loadingMessageKey,
        content: '正在中断任务，请稍后。。。',
        duration: 0,
      });
    },
    onSuccess: (res) => {
      messageApi.open({
        type: 'success',
        key: loadingMessageKey,
        content: '任务已中断',
        duration: 2,
      });
      props.onSuccess?.();
    },
    onError: (err) => {
      messageApi.open({
        type: 'error',
        key: loadingMessageKey,
        content: err.message,
        duration: 2,
      });
    },
    onFinally: () => {
      // messageApi.destroy(loadingMessageKey);
    },
  });
  return canStop ? (
    <>
      {contextHolder}
      <ConfirmButton
        title={'中断任务'}
        content={`确认中断执行任务【${record.name}】吗?`}
        onOk={() =>
          run({
            taskId: record.id,
            projectId: record.projectId,
            organizationId: record.organizationId,
          })
        }
      >
        {({ trigger }) => (
          <Button
            type="text"
            danger
            size="small"
            disabled={loading}
            onClick={trigger}
          >
            <CloseCircleOutlined />
          </Button>
        )}
      </ConfirmButton>{' '}
    </>
  ) : null;
}
