import { useRequest } from 'ahooks';
import { Alert, Card, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlankWrapper } from '../../components/BlankWrapper';
import { getSingleOrganization } from '../../services/organization.service';
import { OrganizationDeleteButton } from './org-delete-button';
import { OrganizationSettingPage } from './OrganizationSettingPage';

export function OrganizationGeneralSettingsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { data, run, loading } = useRequest(getSingleOrganization, {
    manual: true,
    debounceWait: 200,
  });

  useEffect(() => {
    if (params.id) {
      run(params.id);
    }
  }, [params.id]);
  return (
    <BlankWrapper>
      <Spin spinning={loading}>
        <Space direction="vertical">
          <Card title="功能配置">
            {data && (
              <OrganizationSettingPage data={data}></OrganizationSettingPage>
            )}
          </Card>
          <Card title="危险区域">
            <Alert
              message="删除组织"
              description="删除组织之后，将无法恢复数据。"
              type="warning"
              showIcon
              action={
                <OrganizationDeleteButton
                  onSuccess={() => {
                    navigate('/');
                  }}
                  data={data}
                ></OrganizationDeleteButton>
              }
            />
          </Card>
        </Space>
      </Spin>
    </BlankWrapper>
  );
}
