import { useRequest } from 'ahooks';
import { Input, Select, Button, Space, message, Form, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CmtPasswordRules } from '../../components/CmtPasswordRules';
import { GlobalContext } from '../../context';
import { saveAccessToken } from '../../services/auth.service';
import { updateMyPassword } from '../../services/user.service';

export function PasswordForm(props: { onSuccess?: () => void }) {
  const LOGIN_URL = '/login';
  const [form] = Form.useForm<any>();
  const navigate = useNavigate();
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const { loading: updating, run: runUpdate } = useRequest(updateMyPassword, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {
      message.success('密码更新成功， 请重新登录');
      form.resetFields();
      setGlobalContext({});
      saveAccessToken('');
      navigate(LOGIN_URL);
    },
    onError(e, params) {
      message.error('密码更新失败：' + e.message);
    },
  });
  return (
    <Form
      form={form}
      labelCol={{ span: 3 }}
      labelAlign={'left'}
      disabled={updating}
      onFinish={() => {
        runUpdate(form.getFieldsValue());
      }}
    >
      <CmtPasswordRules />
      <FormItem label="旧密码" name={'oldPwd'} rules={[{ required: true }]}>
        <Input.Password />
      </FormItem>
      <FormItem label="新密码" name={'newPwd'} rules={[{ required: true }]}>
        <Input.Password />
      </FormItem>
      <FormItem
        label="确认新密码"
        name={'confirmPwd'}
        rules={[{ required: true }]}
      >
        <Input.Password />
      </FormItem>
      <Form.Item>
        <Space>
          <Button htmlType="submit" loading={updating} disabled={updating}>
            保存
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
