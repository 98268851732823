import Logo from '@/logo.svg';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import {
  JwtMetaItem,
  JwtMetaItemKeys,
} from '../../common/dtos/jwt-token-payload';
import { GlobalContext } from '../../context';
import styles from './style/index.module.less';
import {
  DollarOutlined,
  LogoutOutlined,
  PlusOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  authLogout,
  decodeJwtToken,
  getAccessToken,
  saveAccessToken,
} from '../../services/auth.service';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { TwoFactorStatusIcon } from '../TwoFactorStatusIcon';
import { isElectron } from '../../services/ipc.service';
const { confirm } = Modal;

export function NavBar() {
  const LOGIN_URL = '/login';
  const mainNavItems: ItemType[] = [
    {
      label: <NavLink to="organizations">组织管理</NavLink>,
      key: 'organizations',
    },

    {
      label: <NavLink to="plans">计划任务</NavLink>,
      key: 'plans',
    },
    {
      label: <NavLink to="tasks">运行记录</NavLink>,
      key: 'tasks',
    },
  ];

  const logout = () => {
    confirm({
      title: '提示',
      content: '确认退出吗？',
      onOk: async () => {
        await authLogout();
        setGlobalContext({});
        saveAccessToken('');
        navigate(LOGIN_URL);
      },
    });
  };
  const userMenu: ItemType[] = [
    {
      label: <NavLink to="me">个人设置</NavLink>,
      key: 'profile',
      icon: <UserOutlined />,
    },
    {
      label: <NavLink to="admin">中心设置</NavLink>,
      key: 'org-settings',
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: '退出登录',
      key: 'exit',
      onClick: logout,
      icon: <LogoutOutlined />,
    },
  ];
  const navigate = useNavigate();
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const [jwtMetas, setJwtMetas] = useState<{
    userName: JwtMetaItem | undefined;
    twoFactorEnable: JwtMetaItem | undefined;
    orgName: JwtMetaItem | undefined;
  }>();

  useEffect(() => {
    const jwt = getAccessToken();
    if (jwt) {
      const decoded = decodeJwtToken(jwt);
      setGlobalContext({ ...globalContext, userMetaData: decoded.meta });
    } else {
      navigate(LOGIN_URL);
    }
  }, []);

  useEffect(() => {
    const userMetaData = globalContext.userMetaData;
    const userName = userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.userName
    );
    const twoFactorEnable = userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.twoFactorEnable
    );
    const orgName = userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.organizationName
    );
    setJwtMetas({ userName, twoFactorEnable, orgName });
  }, [globalContext.userMetaData]);

  return (
    <Row
      className={styles['navbar']}
      {...(isElectron() ? { 'data-env': 'electron' } : {})}
    >
      <Col flex={'120px'} className={styles['section']}>
        <Logo />
      </Col>
      <Col flex="auto">
        <Menu
          mode="horizontal"
          items={mainNavItems}
          style={{ minWidth: 0, flex: 'auto', border: 'none' }}
        />
      </Col>
      <Col flex={'200px'} className={styles['section']}>
        <div className={styles['sub']}>
          <Space>
            <Divider type="vertical" />
            <Dropdown menu={{ items: userMenu }} placement="bottomLeft">
              <Space size={'small'}>
                <Avatar size={24}>{jwtMetas?.userName?.v?.split('')[0]}</Avatar>
                <div className={styles['user']}>
                  <Typography.Text>
                    {jwtMetas?.userName?.v}{' '}
                    {jwtMetas?.twoFactorEnable?.v === '1' && (
                      <TwoFactorStatusIcon active={true} />
                    )}
                  </Typography.Text>
                  {/* <Typography.Text type={'secondary'} ellipsis={true}>
                    {jwtMetas?.orgName?.v}
                  </Typography.Text> */}
                </div>
              </Space>
            </Dropdown>
          </Space>
        </div>
      </Col>
    </Row>
  );
}
