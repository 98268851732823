import { Typography } from 'antd';

export function CmtPasswordRules() {
  return (
    <Typography.Paragraph type="secondary">
      <blockquote>
        <Typography.Text>
          基于安全考虑，密码需同时满足以下要求：
        </Typography.Text>
        <ol>
          <li>密码最小长度为8</li>
          <li>至少包含一个大写英文字母</li>
          <li>至少包含一个小写英文字母</li>
          <li>至少包含一个数字</li>
          <li>至少包含一个特殊字符：#?!@$%^&.*-</li>
        </ol>
      </blockquote>
    </Typography.Paragraph>
  );
}
