import { SyncOutlined, PlusOutlined } from '@ant-design/icons';
import { useAntdTable, useSize } from 'ahooks';
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Space,
} from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import {
  ReactNode,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import { ENV_CONFIG } from '../../env-config';
import { PageHeader } from '../PageHeader';

import styles from './index.module.less';

export interface CmtTableProps {
  colums: ColumnType<any>[];
  title: ReactNode;
  description?: ReactNode;
  defaultPageSize?: number;
  containerPaddingSize?: number;
  pollingInterval?: number;
  extends?: (opt: {
    formRef: FormInstance<any>;
    tableSearch: any;
    selectedRowKeys: React.Key[];
  }) => ReactNode;
  secondaryExtends?: (opt: {
    formRef: FormInstance<any>;
    tableSearch: any;
    selectedRowKeys: React.Key[];
    selectedRows: any[];
  }) => ReactNode;
  rowKey: string;
  api: (req: PagingRequest) => Promise<PagingResponse<any>>;
  // onSelected?: (req: PagingRequest) => Promise<PagingResponse<any>>;
  manual?: boolean;
  selectable?: boolean;
  /**
   * 获取checkbox属性, selectable=true时有效
   * @param record
   * @returns
   */
  getCheckboxProps?: (record: any) => { [key: string]: any };
  onDataLoad?: (res: PagingResponse<undefined>) => void;
}
export const CmtTable = forwardRef((props: CmtTableProps, ref) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const onSelectChange = (newSelectedRowKeys: React.Key[], rows: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(rows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: props.getCheckboxProps,
  };
  const containerPaddingSize = props.containerPaddingSize || 16;
  const cardRef = useRef<any>();
  const headerRef = useRef<any>();
  const cardSize = useSize(cardRef);
  const headerSize = useSize(headerRef);
  const [form] = Form.useForm();
  const { tableProps, search, refresh, data } = useAntdTable(
    ({ current, pageSize }, formData: Object) => {
      return props.api({
        current,
        pageSize,
        ...formData,
      });
    },
    {
      manual: !!props.manual,
      form: form,
      debounceWait: 300,
      pollingInterval: props.pollingInterval || 0,
      defaultPageSize:
        props.defaultPageSize || Number(ENV_CONFIG.DEFAULT_PAGE_SIZE),
      onError(e, params) {
        message.error(e.message);
      },
      onSuccess(data, params) {
        setFirstLoad(true);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        props.onDataLoad?.(data);
      },
    }
  );

  useImperativeHandle(ref, () => ({
    refresh: () => {
      refresh();
    },
    submit: () => {
      search.submit();
    },
    resetSelected: () => {
      setSelectedRowKeys([]);
      setSelectedRows([]);
    },
    selectAll: () => {
      const ids: any[] = data?.list.map((x) => x[props.rowKey]) || [];
      setSelectedRowKeys(ids);
      setSelectedRows(data?.list || []);
    },
    form: form,
    selectedRowKeys: selectedRowKeys,
  }));

  tableProps.scroll = {
    y:
      (cardSize?.height || 0) -
      containerPaddingSize * 2 -
      (headerSize?.height || 0) -
      45 - // table header
      44, // paging
  };
  tableProps.pagination.showTotal = (total: number, range: number[]) => (
    <Row>
      <Col flex={'auto'}>
        {props.selectable &&
          props.secondaryExtends?.({
            formRef: form,
            tableSearch: search,
            selectedRowKeys,
            selectedRows,
          })}
      </Col>
      <Col
        flex={'300px'}
        className={styles['paginaztion-total']}
      >{`共${total}条记录，当前显示${range[0]}-${range[1]}`}</Col>
    </Row>
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        background: '#FFF',
        borderRadius: 16,
        boxSizing: 'border-box',
        padding: containerPaddingSize,
        overflow: 'auto',
      }}
      ref={cardRef}
    >
      <div ref={headerRef}>
        <PageHeader
          title={props.title}
          description={props.description}
          extends={props.extends?.({
            formRef: form,
            tableSearch: search,
            selectedRowKeys,
          })}
        ></PageHeader>
      </div>
      <Table
        className={styles['cmt-table']}
        rowSelection={props.selectable ? rowSelection : undefined}
        style={{
          height:
            (cardSize?.height || 0) -
            containerPaddingSize * 2 -
            (headerSize?.height || 0),
        }}
        columns={props.colums}
        rowKey={props.rowKey}
        {...tableProps}
        loading={
          props.pollingInterval && props.pollingInterval > 0
            ? !firstLoad && tableProps.loading
            : tableProps.loading
        }
      />
    </div>
  );
});
