import { Layout } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './main-layout.module.less';
import { NavBar } from '../../components/NavBar';
import WaterMark from '../../components/WaterMark';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../context';
import { JwtMetaItemKeys } from '../../common/dtos/jwt-token-payload';
import { CmtWaterMark } from '../../components/CmtWaterMark';

const { Header, Footer, Content } = Layout;

export function MainLayout() {
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (globalContext.userMetaData) {
      const force2fa =
        globalContext.userMetaData.find(
          (x) => x.k === JwtMetaItemKeys.forceTwoFactor
        )?.v === '1';
      const twofaEnabled =
        globalContext.userMetaData.find(
          (x) => x.k === JwtMetaItemKeys.twoFactorEnable
        )?.v === '1';
      const twofaVerified =
        globalContext.userMetaData.find(
          (x) => x.k === JwtMetaItemKeys.twoFactorVerified
        )?.v === '1';
      if (twofaEnabled && !twofaVerified) {
        navigate('/login');
      }
      if (force2fa && !twofaVerified) {
        navigate('/enable-2fa');
      }
    }
  }, [location, globalContext.userMetaData]);
  return (
    <Layout className={styles['layout']}>
      <Header className={styles['header']}>
        <NavBar />
      </Header>
      <CmtWaterMark>
        <Content className={styles['content']}>
          <Outlet />
        </Content>
      </CmtWaterMark>
    </Layout>
  );
}
