import { useRequest } from 'ahooks';
import { Button, Space, Spin } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { useEffect } from 'react';
import { getAuthTokenOwner } from '../../../services/gpo/util.service';

export function FetchPlatformIdButton(props: {
  projectId: string;
  organizationId: string;
  onSuccess: (id: string) => void;
}) {
  const [messageApi, context] = useMessage();
  const { loading, data, run } = useRequest(getAuthTokenOwner, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {
      props.onSuccess?.(data.orgId);
    },
    onError(e) {
      messageApi.error(e.message);
    },
  });
  return (
    <>
      {context}
      <Button
        type="text"
        size="small"
        loading={loading}
        onClick={() => run(props.organizationId, props.projectId)}
      >
        {loading ? '获取中...' : '点击获取'}
      </Button>
    </>
  );
}
