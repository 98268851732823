import {
  AppstoreOutlined,
  BankOutlined,
  DollarCircleOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Card, Col, Result, Row, Typography } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { NavLink, useRoutes } from 'react-router-dom';
import { BlankWrapper } from '../../../components/BlankWrapper';
import Logo from '@/logo.svg';
import { ENV_CONFIG } from '../../../env-config';
import Table, { ColumnsType } from 'antd/es/table';
import { useRequest, useTitle } from 'ahooks';
import { getCrons } from '../../../services/action.service';
import { ConvertDate } from '../../../common/utils/convert-date';
import { PageHeader } from '../../../components/PageHeader';

export function CronManagementPage() {
  useTitle('Crons', { restoreOnUnmount: true });
  const {
    loading,
    run,
    data: dataSource,
  } = useRequest(getCrons, { debounceWait: 300 });
  const columns: ColumnsType<any> = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      render(value) {
        return <Typography.Text copyable>{value}</Typography.Text>;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render(value) {
        return <Typography.Text copyable>{value}</Typography.Text>;
      },
    },
    {
      title: 'Project',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Next Run Time',
      dataIndex: 'next',
      key: 'next',
      render(value, record, index) {
        return ConvertDate(value, 'YYYY-MM-DD hh:mm:ss');
      },
    },
  ];
  return (
    <BlankWrapper
      breadcrumb={[
        { name: '系统设置', icon: <SettingOutlined /> },
        { name: 'Crons' },
      ]}
    >
      <Card>
        <PageHeader
          title={'Crons'}
          description={
            '激活中的cron，可通过停用计划关闭, Key和name对应一个唯一的计划'
          }
        ></PageHeader>
        <Table
          loading={loading}
          dataSource={dataSource || []}
          columns={columns}
        />
      </Card>
    </BlankWrapper>
  );
}
