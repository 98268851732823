import {
  PagingRequest,
  PagingResponse,
} from '../../common/dtos/paging-request';
import httpClient from '../http-client';
import { GpoCategory } from './category.service';
import { GoodSellStatus } from './goodmarket.service';
/**
 * 平台
 */
export enum GPOPlatform {
  JiCai = '1',
  GoodMarket = '4',
}

/**
 * 商品上架状态
 */
export enum GoodQuotedStates {
  /**
   * 已上架
   */
  Published = '1',
  /**
   * 下架
   */
  Unpublished = '0',
}

export const GoodQuotedStatesOptions = [
  { label: '已添加', value: GoodQuotedStates.Published },
  { label: '未添加', value: GoodQuotedStates.Unpublished },
];

export interface GPOStandardGoodDto {
  goodsBrandName: string;
  goodsClassGuid: string;
  goodsClassName: string;
  goodsCode: string;
  goodsGuid: string;
  goodsPriceGuid: string;
  picturePath: string;
  currentPrice: number;
  sellStatus: GoodSellStatus;
  quoted: GoodQuotedStates;
  [key: string]: any;
}

export function getGoodQuotedStatesName(status: GoodQuotedStates) {
  return GoodQuotedStatesOptions.find((x) => x.value === status)?.label || '';
}

export async function queryStandardGoodsApi(
  params: PagingRequest
): Promise<PagingResponse<GPOStandardGoodDto>> {
  if (params.supplierAgreementGuid && params.goodsClassGuid) {
    const res: any = await httpClient.get('/gpo/standard-goods', {
      params,
    });
    return res;
  }
  return {
    current: params.current,
    pageSize: params.pageSize,
    list: [],
    total: 0,
  };
}

export async function queryGoodsSupplierAgreements(params: {
  projectId: string;
}): Promise<
  {
    supplierAgreementGuid: string;
    agreementName: string;
    regionName: string;
    [key: string]: any;
  }[]
> {
  const res: any = await httpClient.get(
    '/gpo/standard-goods/goods-supplier-agreements',
    {
      params,
    }
  );
  return res;
}

export async function queryGoodsCategoryBySupplierAgreements(params: {
  projectId: string;
  agreementId: string;
}): Promise<GpoCategory[]> {
  const res: any = await httpClient.get(
    '/gpo/standard-goods/goods-category-by-supplier-agreements',
    {
      params,
    }
  );
  return res;
}

export async function changeQuotedStateApi(params: {
  goodsPriceGuid: string;
  supplierAgreementGuid: string;
}): Promise<void> {
  const res: any = await httpClient.post(
    '/gpo/standard-goods/batch-add',
    params
  );
  return res;
}

export async function queryAllBrandsByClassAndAgreements(params: {
  agreementType: string;
  goodsClassGuid: string;
  projectId?: string;
}): Promise<
  {
    goodsBrandGuid: string;
    goodsBrandName: string;
  }[]
> {
  const res: any = await httpClient.get(
    '/gpo/standard-goods/goods-brands-by-class-and-agreements',
    {
      params,
    }
  );
  return res;
}
