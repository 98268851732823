import { ClockCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Popover, Space, Spin, Typography } from 'antd';
import { useEffect } from 'react';
import { ConvertDate, HumanizeDate } from '../../common/utils/convert-date';
import { getSingleCron } from '../../services/action.service';

function PlanCronNextTime(props: { id: string }) {
  const { run, loading, data } = useRequest(getSingleCron, {
    debounceWait: 300,
    manual: true,
  });
  useEffect(() => {
    run(props.id);
  }, []);
  return (
    <Spin spinning={loading}>
      {data?.next ? (
        <Typography.Text>
          {ConvertDate(data.next || '', 'YYYY-MM-DD HH:mm:ss')}（
          {HumanizeDate(new Date().toISOString(), data.next)}）
        </Typography.Text>
      ) : (
        '获取中...'
      )}
    </Spin>
  );
}

export function PlanCronView(props: {
  planId: string;
  active: boolean;
  cron: string;
}) {
  return (
    <Popover
      title="下次执行时间"
      content={
        <Space style={{ width: '100%' }} direction="vertical">
          <Typography.Text
            style={{ cursor: 'default', whiteSpace: 'nowrap' }}
            code
          >
            {props.cron}
          </Typography.Text>
          {props.active ? <PlanCronNextTime id={props.planId} /> : '计划已停用'}
        </Space>
      }
    >
      <Button type="link" size="small">
        <ClockCircleOutlined />
      </Button>
    </Popover>
  );
}
