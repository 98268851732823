import { ArrowUpOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../components/CmtPopConfirmAction';
import {
  ECSInstance,
  removeEcsInstancesApi,
  upgradeCmtApi,
} from '../../services/ecs.service';

const { Text, Paragraph } = Typography;
export function UpgradeCmtButton(props: {
  data: ECSInstance;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={upgradeCmtApi}
      title={
        <Space direction="vertical" style={{ maxWidth: 300 }}>
          <Text strong>{`确定升级吗？`}</Text>
          <Paragraph>{props.data.instanceName}</Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run({
          instanceIds: [data.instanceId],
          regionId: data.region,
          organizationId: data.organizationId,
          projectId: data.projectId,
        });
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button type="text" size="small" disabled={loading} loading={loading}>
          <ArrowUpOutlined />
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
