import { Col, Row, Space, Typography } from 'antd';
import React, { ReactNode } from 'react';
import styles from './index.module.less';

export function PageHeader(props: {
  title: React.ReactNode;
  description?: React.ReactNode;
  extends?: ReactNode;
}) {
  return (
    <div className={styles['pageHeader']}>
      <Space>
        <Typography.Title level={5} className={styles['title']}>
          {props.title}
        </Typography.Title>
        {props.description && (
          <Typography.Text type="secondary">
            {props.description}
          </Typography.Text>
        )}
      </Space>
      <div>{props.extends}</div>
    </div>
  );
}
