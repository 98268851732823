import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';
import { Plan } from './plan.service';

export enum TaskStatus {
  Draft = 'Draft',
  Ready = 'Ready',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Stopped = 'Stopped',
  Paused = 'Paused',
}

export const TaskStatusOptions = [
  { label: '待执行', value: TaskStatus.Ready },
  { label: '执行中', value: TaskStatus.InProgress },
  { label: '已完成', value: TaskStatus.Completed },
  { label: '创建中', value: TaskStatus.Draft },
  { label: '已中断', value: TaskStatus.Stopped },
  { label: '暂停中', value: TaskStatus.Paused },
];

export function getTaskStatusName(status: TaskStatus) {
  return TaskStatusOptions.find((x) => x.value === status)?.label || '';
}

export interface Task {
  id: string;
  organizationId: string;
  projectId: string;
  projectName: string;
  name: string;
  jobId: string;
  jobPayload: string;
  message: string;
  status: TaskStatus;
  createdAt: string;
  updatedAt: string;
  progress: number;
  planId: string;
}
export async function queryTaskApi(
  params: PagingRequest
): Promise<PagingResponse<Task>> {
  const res: any = await httpClient.get('/tasks', { params });
  return res;
}

export async function queryMyTaskApi(
  params: PagingRequest
): Promise<PagingResponse<Task>> {
  const res: any = await httpClient.get('/tasks/mine', { params });
  return res;
}

export enum EventType {
  Info = 'Info',
  Warn = 'Warn',
  Success = 'Success',
  Error = 'Error',
}
export interface Event {
  id: string;
  taskId: string;
  message: string;
  createdAt: string;
  type: EventType;
}

export const EventTypeOptions = [
  { label: '提示', value: EventType.Info },
  { label: '警告', value: EventType.Warn },
  { label: '成功', value: EventType.Success },
  { label: '失败', value: EventType.Error },
];

export function getEventTypeName(status: EventType) {
  return EventTypeOptions.find((x) => x.value === status)?.label || '';
}

export async function queryEventApi(
  params: PagingRequest
): Promise<PagingResponse<Task>> {
  const res: any = await httpClient.get('/events', { params });
  return res;
}

export interface Action {
  id: string;
  name: string;
  internalName?: string;
  description: string;
  queueName: string;
  processName: string;
  payloadSchema: any;
  enabled: boolean;
  maxBatchCount: number;
}

export async function queryActionsApi(
  params: PagingRequest
): Promise<PagingResponse<Action>> {
  const res: any = await httpClient.get('/actions', { params });
  return res;
}

export async function queryInternalActionsApi(
  params: PagingRequest
): Promise<PagingResponse<Action>> {
  const res: any = await httpClient.get('/actions/internal', { params });
  return res;
}

export async function querySingleActionApi(id: string): Promise<Action> {
  const res: any = await httpClient.get(`/actions/${id}`);
  return res;
}

export interface ExecuteActionDto {
  taskName: string;
  actionId: string;
  projectId: string;
  payload: any;
}

export async function executeActionApi(
  params: ExecuteActionDto
): Promise<Task> {
  const res: any = await httpClient.post('/actions/exec', params);
  return res;
}
