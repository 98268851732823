import { PagingRequest, PagingResponse } from '../common/dtos/paging-request';
import httpClient from './http-client';

/**
 * 计划类型
 */
export enum PlanType {
  /**
   * 立刻执行
   */
  Immediate = 'Immediate',
  /**
   * 计划执行
   */
  Cron = 'Cron',
}

export const PlanTypeOptions = [
  { value: PlanType.Immediate, label: '单次任务' },
  { value: PlanType.Cron, label: '周期任务' },
];

export function getPlanTypeName(type: PlanType) {
  return PlanTypeOptions.find((x) => x.value === type)?.label;
}

export interface Plan {
  id: string;
  name: string;
  organizationId: string;
  projectId: string;
  projectName: string;
  actionId: string;
  actionName: string;
  type: PlanType;
  cron: string;
  payload: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export async function queryPlansApi(
  params: PagingRequest
): Promise<PagingResponse<Plan>> {
  const res: any = await httpClient.get('/plans', { params });
  return res;
}
export interface CreatePlanDto {
  name: string;
  projectId: string;
  actionId: string;
  type: PlanType;
  cron: string;
  payload: string;
}

export interface UpdatePlanDto extends CreatePlanDto {}

export async function createPlanApi(params: CreatePlanDto) {
  const res: any = await httpClient.post('/plans', params);
  return res;
}

export async function createInternalPlanApi(params: CreatePlanDto) {
  const res: any = await httpClient.post('/plans/internal', params);
  return res;
}

export async function updatePlanApi(planId: string, params: UpdatePlanDto) {
  return await httpClient.patch(`/plans/${planId}`, params);
}

export async function deletePlanApi(planId: string) {
  return await httpClient.delete(`/plans/${planId}`);
}

export async function updatePlanActive(
  planId: string,
  params: { active: boolean }
) {
  return await httpClient.patch(`/plans/${planId}/active`, params);
}

export async function runPlanApi(planId: string) {
  return await httpClient.post(`/plans/${planId}/run`);
}
