import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { CmtPopConfirmAction } from '../../components/CmtPopConfirmAction';
import {
  deleteOrganization,
  Organization,
} from '../../services/organization.service';
import { deletePlanApi, Plan, runPlanApi } from '../../services/plan.service';
const { Text, Paragraph } = Typography;

export function OrganizationDeleteButton(props: {
  data: Organization | undefined;
  onSuccess?: () => void;
}) {
  return (
    <CmtPopConfirmAction
      service={deleteOrganization}
      title={
        <Space direction="vertical">
          <Text strong>删除确认</Text>
          <Paragraph style={{ maxWidth: 300 }}>
            {' '}
            确定删除【{props.data?.name}】吗? ?
          </Paragraph>
        </Space>
      }
      onOk={({ data, run }) => {
        run(data?.id);
      }}
      data={props.data}
      onSuccess={props.onSuccess}
    >
      {({ loading }) => (
        <Button danger disabled={loading} loading={loading}>
          <DeleteOutlined /> 删除
        </Button>
      )}
    </CmtPopConfirmAction>
  );
}
