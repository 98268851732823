const ipcRenderer = window.require?.('electron')?.ipcRenderer;
export const WellKnownMainMessageChannel = 'message-to-main';

export interface IpcRequest {
  _instanceId?: string;
  url: string;
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'OPTIONS' | 'PATCH';
  data?: any;
  queryParams?: { [key: string]: any };
}

export interface IpcResponse<T = any> {
  status: number;
  data: T;
}

export interface IpcBroadcast {
  type: string;
  data?: any;
  message?: any;
}

export function isElectron() {
  return !!window.require && ipcRenderer;
}

export async function sendIpcMessageRequest(
  req: IpcRequest
): Promise<IpcResponse> {
  req._instanceId = Date.now().toString();
  return new Promise((resolve, reject) => {
    ipcRenderer.once(req._instanceId, (event: any, data: IpcResponse) => {
      if (data.status == 200) {
        resolve(data);
      } else {
        reject(data);
      }
    });
    ipcRenderer.send(WellKnownMainMessageChannel, req);
  });
}
