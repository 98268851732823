import { useRequest } from 'ahooks';
import { Button, Form, Input, InputNumber, message, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { twoFactorLogin } from '../../services/auth.service';

export function TwoFactorConfirm(props: {
  tempAccessToken: string;
  onSuccess?: (data: { accessToken: string }) => void;
}) {
  const [form] = Form.useForm();
  const { loading, run } = useRequest(twoFactorLogin, {
    debounceWait: 300,
    manual: true,
    onSuccess(res) {
      props.onSuccess?.(res);
    },
    onError(err) {
      message.error(err.message);
      form.resetFields();
    },
  });
  return (
    <Form
      form={form}
      onFinish={() => {
        debugger;
      }}
    >
      <Typography.Paragraph>
        您的账号已启用双重身份验证， 请输入验证器上的验证码。
      </Typography.Paragraph>
      <FormItem
        name="code"
        rules={[
          { required: true, message: '' },
          { pattern: /\d{6}/, message: '' },
        ]}
      >
        <Input
          placeholder="验证码：123456"
          size="large"
          style={{ width: '100%', textAlign: 'center' }}
          onChange={() => {
            form.validateFields().then(() => {
              const payload = form.getFieldsValue();
              run(props.tempAccessToken, payload);
            });
          }}
        />
      </FormItem>
    </Form>
  );
}
