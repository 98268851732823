import {
  DesktopOutlined,
  FieldTimeOutlined,
  FileOutlined,
  HddOutlined,
  HolderOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { NavLink, useRoutes } from 'react-router-dom';
import { SubLayout } from '../../layouts/sub-layout/sub-layout';

export function AdminSettingsPage() {
  const projectNavItems: ItemType[] = [
    {
      label: <NavLink to="members">管理员</NavLink>,
      icon: <UserOutlined />,
      key: 'members',
    },
    {
      label: <NavLink to="system-params">系统参数</NavLink>,
      icon: <HolderOutlined />,
      key: 'system-params',
    },
    {
      label: <NavLink to="crons">Crons</NavLink>,
      icon: <FieldTimeOutlined />,
      key: 'crons',
    },
  ];
  return <SubLayout menuItems={projectNavItems}></SubLayout>;
}
