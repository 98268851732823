import { useRequest } from 'ahooks';
import { message, Button, Input, Form, Typography, Spin, Alert } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import {
  Project,
  queryProjectApi,
  updateSingleProjectApi,
} from '../../../services/project.service';
import { InfiniteSelect } from '../../InfiniteSelect';

export function ExecutorSettingForm(props: { project: Project | undefined }) {
  const [form] = Form.useForm<any>();
  useEffect(() => {
    if (props.project) {
      form.setFieldsValue({
        shareExecutorChannel: props.project.shareExecutorChannel,
      });
    }
  }, [props.project]);

  const { loading: updating, run: runUpdate } = useRequest(
    updateSingleProjectApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('设置保存成功');
        // props.onSuccess?.(params[1]);
      },
      onError(e, params) {
        message.error('设置保存失败：' + e.message);
      },
    }
  );
  return (
    <Spin spinning={updating}>
      <Form
        disabled={updating}
        form={form}
        labelCol={{ span: 3 }}
        labelAlign={'left'}
        onFinish={() => {
          runUpdate(props.project?.id || '', form.getFieldsValue());
        }}
      >
        <Alert
          message={
            <p>
              允许多个项目复用执行器。选择并保存后，【
              {props.project?.name}】将会使用【选中项目】的执行器执行操作。
              <br />
              若【{props.project?.name}
              】的执行器被其他项目依赖，请保留默认执行器，且创建对应的执行器。
            </p>
          }
        ></Alert>
        <br />
        <FormItem label="复用执行器" name="shareExecutorChannel">
          <InfiniteSelect
            defaultOptions={[{ label: '默认执行器', value: '' }]}
            defaultParams={{ organizationId: props.project?.organizationId }}
            style={{ width: '100%' }}
            api={queryProjectApi}
            option={(item: Project) => {
              return {
                label: item.name,
                value: item.id,
                disabled: props.project?.id === item.id,
              };
            }}
          />
        </FormItem>
        <Form.Item>
          <Button htmlType="submit">保存</Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
