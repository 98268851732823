import { useRequest } from 'ahooks';
import { Form, message, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { queryGoodsSupplierAgreements } from '../../../services/gpo/standard-goods.service';
const { Text } = Typography;

export function GpoJiCaiAgreementSelect(props: {
  value?: string;
  style?: React.CSSProperties;
  onChange?: (val: string) => void;
  queryParams: { projectId: string };
}) {
  const [agreements, setAgreements] = useState<{ label: any; value: string }[]>(
    []
  );
  const {
    loading,
    data,
    run: querySa,
  } = useRequest(queryGoodsSupplierAgreements, {
    debounceWait: 300,
    manual: true,
    onSuccess: (data, params) => {
      const opts = data.map((x) => {
        return {
          label: (
            <div>
              <div>{x.agreementName}</div>
              <Text type="secondary">{x.regionName}</Text>
            </div>
          ),
          value: x.supplierAgreementGuid,
        };
      });
      setAgreements(opts);
    },
    onError(e, params) {
      message.error(`获取协议失败：${e.message}`);
    },
  });
  useEffect(() => {
    if (props.queryParams.projectId) {
      querySa(props.queryParams);
    }
  }, [props.queryParams.projectId]);
  return (
    <Select
      style={props.style}
      loading={loading}
      onChange={props.onChange}
      options={agreements}
      value={props.value}
    ></Select>
  );
}
