import {
  ApartmentOutlined,
  AppstoreAddOutlined,
  DeploymentUnitOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useTitle } from 'ahooks';
import {
  Typography,
  Space,
  Input,
  Button,
  Select,
  Form,
  Popover,
  Result,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRef, useState } from 'react';
import { ConvertDate, HumanizeDate } from '../../common/utils/convert-date';
import { BlankWrapper } from '../../components/BlankWrapper';
import { CmtTable } from '../../components/CmtTable';
import {
  Organization,
  queryOrganizationApi,
} from '../../services/organization.service';
import { OrganizationDeleteButton } from '../organization-mgmt/org-delete-button';
import { OrganizationAddButton } from './org-add-button';
import { Link } from 'react-router-dom';

export function OrganizationPage() {
  useTitle('组织管理', { restoreOnUnmount: true });
  const tableRef = useRef<any>(null);
  const columns: ColumnsType<Organization> = [
    {
      title: '名称',
      dataIndex: 'name',
      render(value, record, index) {
        return <Link to={`${record.id}`}>{value}</Link>;
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      render(value, record, index) {
        return (
          <Typography.Text copyable style={{ textTransform: 'uppercase' }}>
            {record.id}
          </Typography.Text>
        );
      },
    },
    {
      title: '描述',
      dataIndex: 'description',
    },
    {
      title: '强制2FA',
      dataIndex: 'force2fa',
      render(value, record, index) {
        return value ? '开启' : '关闭';
      },
    },
    {
      title: '创建日期',
      dataIndex: 'createdAt',
      render(value) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
  ];

  return (
    <BlankWrapper
      breadcrumb={[{ name: '组织管理', icon: <ApartmentOutlined /> }]}
    >
      <CmtTable
        ref={tableRef}
        title="所有组织"
        description=""
        rowKey="id"
        colums={columns}
        api={queryOrganizationApi}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item name={'name'} label={'名称'}>
                  <Input
                    style={{ width: 180 }}
                    placeholder="输入名称"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
              <OrganizationAddButton></OrganizationAddButton>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
