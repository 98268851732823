import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn'; // 导入本地化语言
dayjs.locale('zh-cn'); // 使用本地化语言
dayjs.extend(relativeTime);
// dayjs.extend(duration);

export function ConvertDate(
  input: string | number,
  format = 'YYYY-MM-DD HH:mm'
) {
  return dayjs(new Date(input)).format(format);
}

export function HumanizeDate(from: string, target: string) {
  return dayjs(target).from(from); // a minute
}
