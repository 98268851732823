import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  PauseOutlined,
  SmallDashOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import { TaskStatus } from '../../services/task.service';

export function CmtTaskStatusIcon(props: { v: TaskStatus }) {
  switch (props.v) {
    case TaskStatus.Completed:
      return (
        <Typography.Text type={'success'}>
          <CheckCircleOutlined />
        </Typography.Text>
      );
    case TaskStatus.Stopped:
      return (
        <Typography.Text type={'danger'}>
          <CloseCircleOutlined />
        </Typography.Text>
      );
    case TaskStatus.Ready:
      return <LoadingOutlined />;
    case TaskStatus.Paused:
      return <PauseOutlined />;
    case TaskStatus.InProgress:
      return <LoadingOutlined />;
    default:
      return <SmallDashOutlined />;
  }
}
