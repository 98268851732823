import { Result, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { ENV_CONFIG } from '../../../env-config';

export function Exception404() {
  return (
    <Result
      status="error"
      icon={''}
      title="出错了"
      subTitle="你正在访问的页面出错了"
      extra={
        <NavLink to={ENV_CONFIG.DEFAULT_PAGE}>
          <Button type="primary">返回首页</Button>
        </NavLink>
      }
    />
  );
}
