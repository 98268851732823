import { JwtTokenPayload } from '../common/dtos/jwt-token-payload';
import httpClient from './http-client';
import jwt_decode from 'jwt-decode';

export async function authLogin(
  email: string,
  pwd: string
): Promise<{ accessToken: string }> {
  const res: any = await httpClient.post('/auth/login', {
    email,
    pwd,
  });
  return res;
}

export async function authLogout(): Promise<unknown> {
  const res: any = await httpClient.post('/auth/logout');
  return res;
}

export async function twoFactorLogin(
  token: string,
  param: {
    code: string;
  }
): Promise<{ accessToken: string }> {
  const res: any = await httpClient.post(
    '/auth/two-factor-authentication/verify',
    param,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
}

export async function changeProject(params: {
  projectId: string;
}): Promise<{ accessToken: string }> {
  const res: any = await httpClient.post('/auth/change-project', params);
  return res;
}

export function decodeJwtToken(jwtToken: string): JwtTokenPayload {
  return jwt_decode(jwtToken);
}

export function saveAccessToken(jwtToken: string) {
  localStorage.setItem('cmt_session', jwtToken);
}

export function getAccessToken() {
  return localStorage.getItem('cmt_session');
}
