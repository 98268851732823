import { ApartmentOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { message, Row, Col, Space, Card, Alert, Button, Tabs, Form, Input } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSingleProjectApi,
  deleteSingleProjectApi,
  ProjectPlatforms,
} from '../../../services/project.service';
import { CmtPopConfirmAction } from '../../CmtPopConfirmAction';
import { ProjectForm } from '../project-form';
import { DingDingSettingForm } from './dingding-setting-form';
import { ExecutorSettingForm } from './executor-setting-form';
import { MetabaseSettingForm } from './metabase-setting-form';
import { NetmaskSettingForm } from './netmask-setting-form';
import { SessionSettingForm } from './session-setting-form';
import { LoginTest } from './login-test';

export function ProjectGeneralSettings(props: {
  projectId: string;
  onSuccess?: () => void;
  onDelete?: () => void;
}) {
  const { data, loading, run } = useRequest(getSingleProjectApi, {
    manual: true,
    onSuccess(data, params) {},
    onError(e) {
      message.error(e.message);
    },
  });

  useEffect(() => {
    if (props.projectId) {
      run(props.projectId);
    }
  }, [props.projectId]);

  let items = [
    {
      label: '项目设置',
      key: '1',
      children: (
        <ProjectForm
          project={data}
          onSuccess={props.onSuccess}
          hideSecondaryButton={true}
        />
      ),
    },
    {
      label: '通知设置',
      key: '2',
      children: <DingDingSettingForm project={data} />,
    },
    // {
    //   label: '报表设置',
    //   key: '3',
    //   children: <MetabaseSettingForm project={data} />,
    // },
    // {
    //   label: '网络设置',
    //   key: '5',
    //   platform: [ProjectPlatforms.GPO],
    //   children: <NetmaskSettingForm project={data} />,
    // },
    {
      label: '执行器',
      key: '6',
      platform: [ProjectPlatforms.GPO],
      children: <ExecutorSettingForm project={data} />,
    },
    {
      label: '服务Session',
      key: '7',
      children: <SessionSettingForm project={data} />,
    },
    {
      label: '危险区域',
      key: '4',
      children: (
        <Alert
          message="删除项目"
          description="删除项目之后，将无法恢复数据，所有周期任务将会被停止。"
          type="warning"
          showIcon
          action={
            <CmtPopConfirmAction
              data={props.projectId}
              service={deleteSingleProjectApi}
              onOk={function ({ run, data }): void {
                run(data);
              }}
              title={'确认删除项目吗？'}
              onSuccess={props.onDelete}
            >
              {({ loading }) => (
                <Button type="primary" danger loading={loading}>
                  删除
                </Button>
              )}
            </CmtPopConfirmAction>
          }
        />
      ),
    },
    // {
    //   label: '登录测试',
    //   key: '8',
    //   platform: [ProjectPlatforms.GPO],
    //   children: (
    //     <LoginTest project={data}/>
    //   ),
    // },
  ];
  items = items.filter(
    (x) =>
      !x.platform?.length ||
      (data?.platform && x.platform.includes(data?.platform))
  );
  return <Tabs defaultActiveKey="1" items={items}></Tabs>;
}
