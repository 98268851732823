import { useRequest, useTitle } from 'ahooks';
import { Button, Card, Divider, Form, Input, Spin, Typography } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { useEffect } from 'react';
import { JsonSchemaInput } from '../../components/JsonSchemaInput';
import {
  getOrganizationSchema,
  Organization,
  updateOrganization,
} from '../../services/organization.service';

export function OrganizationSettingPage(props: {
  data: Organization;
  onSuccess?: (res: boolean) => void;
}) {
  useTitle('功能设置', { restoreOnUnmount: true });
  const [form] = Form.useForm<any>();
  const {
    data: schema,
    loading: loadingSchema,
    run: loadSchema,
  } = useRequest(getOrganizationSchema, {
    manual: true,
  });

  const [message, context] = useMessage();
  const { loading: updating, run: runUpdate } = useRequest(updateOrganization, {
    manual: true,
    debounceWait: 300,
    onSuccess(data, params) {
      message.success('更新成功');
      props.onSuccess?.(true);
    },
    onError(e, params) {
      message.error('更新失败：' + e.message);
    },
  });

  useEffect(() => {
    loadSchema();
  }, []);

  useEffect(() => {
    if (props.data) {
      const formValue = {
        name: props.data.name,
        description: props.data.description,
        settings: JSON.parse(props.data.settings || '{}'),
      };
      form.setFieldsValue(formValue);
    }
  }, [props.data?.id]);

  return (
    <div style={{ width: '800px', overflowX: 'hidden' }}>
      <Spin spinning={loadingSchema || updating}>
        {context}
        {schema && (
          <>
            <Form
              form={form}
              layout="vertical"
              onFinish={(v) => {
                const payload = { ...v, settings: JSON.stringify(v.settings) };
                runUpdate(props.data.id, payload);
              }}
            >
              <Form.Item name="name" label="名称" rules={[{ required: true }]}>
                <Input></Input>
              </Form.Item>
              <Form.Item
                name="description"
                label="描述"
                rules={[{ required: true }]}
              >
                <Input></Input>
              </Form.Item>
              <Divider></Divider>
              <Form.Item name="settings" label="权限配置">
                <JsonSchemaInput
                  layoutResponsive={false}
                  projectId=""
                  schema={schema}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Spin>
    </div>
  );
}
