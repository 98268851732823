import { useRequest } from 'ahooks';
import { message, Button, Input, Form, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect } from 'react';
import {
  Project,
  updateSingleProjectApi,
} from '../../../services/project.service';

export function DingDingSettingForm(props: { project: Project | undefined }) {
  const [form] = Form.useForm<any>();
  useEffect(() => {
    if (props.project) {
      form.setFieldsValue({
        dingdingWebhookUrl: props.project.dingdingWebhookUrl,
        weixinWebhookUrl: props.project.weixinWebhookUrl,
      });
    }
  }, [props.project]);

  const { loading: updating, run: runUpdate } = useRequest(
    updateSingleProjectApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('通知设置保存成功');
        // props.onSuccess?.(params[1]);
      },
      onError(e, params) {
        message.error('通知设置保存失败：' + e.message);
      },
    }
  );
  return (
    <Form
      disabled={updating}
      form={form}
      labelCol={{ span: 3 }}
      labelAlign={'left'}
      onFinish={() => {
        runUpdate(props.project?.id || '', form.getFieldsValue());
      }}
    >
      <Typography.Paragraph type="secondary">
        设置HOOK将可以接收任务消息
      </Typography.Paragraph>
      <FormItem
        label="钉钉通知"
        name="dingdingWebhookUrl"
        help={
          <Button
            type="link"
            onClick={() =>
              window.open(
                'https://open.dingtalk.com/document/group/custom-robot-access'
              )
            }
          >
            如何获取hook地址?
          </Button>
        }
      >
        <Input placeholder="钉钉机器人webhook地址，https://oapi.dingtalk.com/robot/send?access_token=XXXXXX"></Input>
      </FormItem>
      <FormItem
        label="企业微信群"
        name="weixinWebhookUrl"
        help={
          <Button
            type="link"
            onClick={() =>
              window.open(
                'https://open.work.weixin.qq.com/help2/pc/14931?person_id=1'
              )
            }
          >
            如何获取hook地址?
          </Button>
        }
      >
        <Input placeholder="企业微信群机器人webhook地址，https://qyapi.weixin.qq.com/cgi-bin/webhook/send?key=XXXXXX"></Input>
      </FormItem>
      {/* <FormItem label="Slack">
      <Input></Input>
    </FormItem> */}
      <Form.Item>
        <Button htmlType="submit">保存</Button>
      </Form.Item>
    </Form>
  );
}
