import httpClient from './http-client';

export async function stopTaskApi(params: {
  taskId: string;
  projectId: string;
  organizationId: string;
}): Promise<void> {
  const res: any = await httpClient.post('/actions/interrupt', params);
  return res;
}

export async function pauseTaskApi(params: {
  taskId: string;
  projectId: string;
  organizationId: string;
}): Promise<void> {
  const res: any = await httpClient.post('/actions/pause', params);
  return res;
}

export async function resumeTaskApi(params: {
  taskId: string;
  projectId: string;
  organizationId: string;
}): Promise<void> {
  const res: any = await httpClient.post('/actions/resume', params);
  return res;
}
export async function getCrons(): Promise<
  { key: string; next: string; name: string; projectName: string }[]
> {
  const res: any = await httpClient.get('/actions/cron');
  return res;
}

export async function getSingleCron(
  id: string
): Promise<{ key: string; next: string; name: string; projectName: string }> {
  const res: any = await httpClient.get('/actions/cron/' + id);
  return res;
}
