import React, { createContext, ReactText } from 'react';
import { Project } from './services/project.service';

export interface GlobalContextState {
  userMetaData?: { k: string; v: string }[];
  currentProject?: Project;
}

export const GlobalContext = createContext<
  [GlobalContextState, React.Dispatch<React.SetStateAction<GlobalContextState>>]
>([{}, () => {}]);
