import {
  ApartmentOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useRequest, useTitle } from 'ahooks';
import {
  Typography,
  Space,
  Input,
  Button,
  Select,
  Form,
  Popover,
  Result,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useRef, useState } from 'react';
import { ConvertDate } from '../../common/utils/convert-date';
import { BlankWrapper } from '../../components/BlankWrapper';
import { CmtTable } from '../../components/CmtTable';
import { Project, queryProjectApi } from '../../services/project.service';
import { useParams } from 'react-router-dom';
import { CmtModalButton } from '../../components/CmtModalButton';
import { ProjectForm } from '../../components/CmtProjects/project-form';
import { ProjectSettingSettingsButton } from './project-settings-button';
import { getSingleOrganization } from '../../services/organization.service';
import { useFeatureValue } from '../../components/FeatureToggle';

export function ProjectPage() {
  useTitle('项目管理', { restoreOnUnmount: true });
  const params = useParams();
  const tableRef = useRef<any>(null);
  const [projectCount, setProjectCount] = useState(0);
  const { data: org, run: pullOrg } = useRequest(getSingleOrganization, {
    manual: true,
    debounceWait: 300,
  });
  const maxProjectCount = useFeatureValue<number>(
    'org.max_project_count',
    org?.settings
  );

  useEffect(() => {
    params.id && pullOrg(params.id || '');
  }, [params.id]);

  const columns: ColumnsType<Project> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 320,
      fixed: 'left',
      render(value, record, index) {
        return (
          <Typography.Text copyable style={{ textTransform: 'uppercase' }}>
            {value}
          </Typography.Text>
        );
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      fixed: 'left',
      width: 160,
    },
    {
      title: '描述',
      dataIndex: 'description',
      width: 160,
    },
    {
      title: '平台',
      dataIndex: 'platform',
      width: 50,
    },
    // {
    //   title: '网段',
    //   dataIndex: 'netmask',
    //   width: 160,
    // },
    {
      title: '验证方式',
      dataIndex: 'authType',
      width: 100,
    },
    {
      title: '创建日期',
      dataIndex: 'createdAt',
      width: 150,
      render(value) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      width: 150,
      render(value) {
        return ConvertDate(value, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: 120,
      fixed: 'right',
      render(value, record, index) {
        return (
          <Space>
            <ProjectSettingSettingsButton
              data={record}
              onSuccess={tableRef.current.refresh}
            ></ProjectSettingSettingsButton>
            {/* <ProjectDeleteButton
              data={record}
              onSuccess={tableRef.current.refresh}
            ></ProjectDeleteButton> */}
          </Space>
        );
      },
    },
  ];

  return (
    <BlankWrapper>
      <CmtTable
        ref={tableRef}
        title={`项目管理`}
        description=""
        rowKey="id"
        colums={columns}
        api={queryProjectApi}
        onDataLoad={(data) => {
          setProjectCount(data.total);
        }}
        extends={({ formRef, tableSearch }) => {
          const { submit } = tableSearch;
          return (
            <Space>
              <Form form={formRef} layout={'inline'}>
                <Form.Item
                  hidden
                  name="organizationId"
                  initialValue={params.id}
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item name={'keyword'} label={'名称'}>
                  <Input
                    style={{ width: 120 }}
                    placeholder="输入项目名称"
                    onChange={submit}
                    allowClear
                  ></Input>
                </Form.Item>
              </Form>
              <Button type="default" onClick={submit}>
                <SyncOutlined /> 刷新
              </Button>
              <CmtModalButton
                title={'添加项目'}
                trigger={({ show }) => (
                  <Button type="primary" onClick={show}>
                    <PlusOutlined /> 添加项目 （{projectCount}/
                    {maxProjectCount || '无限'}）
                  </Button>
                )}
              >
                {({ show, hide }) => (
                  <ProjectForm
                    project={
                      {
                        organizationId: params.id || '',
                        platform: 'gpo',
                      } as any
                    }
                    onSuccess={() => {
                      hide();
                      tableRef.current.refresh();
                    }}
                  />
                )}
              </CmtModalButton>
            </Space>
          );
        }}
      ></CmtTable>
    </BlankWrapper>
  );
}
