import {
  SafetyCertificateOutlined,
  SafetyCertificateTwoTone,
} from '@ant-design/icons';

export function TwoFactorStatusIcon(props: { active: boolean }) {
  return props.active ? (
    <SafetyCertificateTwoTone twoToneColor="#52c41a" />
  ) : (
    <SafetyCertificateOutlined />
  );
}
