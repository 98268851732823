import { sendIpcMessageRequest } from './ipc.service';

export async function getBrowserState(type: 'PC' | 'MOBILE') {
  const res = await sendIpcMessageRequest({
    method: 'GET',
    url: '/browser-states',
    data: {
      url: type === 'MOBILE' ? 'https://m.jd.com' : 'https://www.jd.com',
    },
  });
  return res;
}
