import React, { useContext } from 'react';
import { JwtMetaItemKeys } from '../../common/dtos/jwt-token-payload';
import { GlobalContext } from '../../context';
import WaterMark from '../WaterMark';

export function CmtWaterMark(props: { children: React.ReactElement }) {
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const userName =
    globalContext.userMetaData?.find((x) => x.k === JwtMetaItemKeys.userName)
      ?.v || '';
  const mobile =
    globalContext.userMetaData?.find((x) => x.k === JwtMetaItemKeys.mobile)
      ?.v || '';
  const org =
    globalContext.userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.organizationName
    )?.v || '';
  return (
    <WaterMark
      content={`${userName}(${mobile})`}
      fontSize={14}
      gapX={100}
      gapY={130}
      fontColor={'rgba(0,0,0,0.08)'}
    >
      {props.children}
    </WaterMark>
  );
}
