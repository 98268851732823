import { PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Spin, Typography } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import { REGEXS } from '../../common/utils/formats';
import { CmtModalButton } from '../../components/CmtModalButton';
import { createOrganization } from '../../services/organization.service';

function OrganizationAddForm(props: { onSuccess?: () => void }) {
  const [messageApi, context] = useMessage();
  const [form] = Form.useForm<any>();
  const { loading, run } = useRequest(createOrganization, {
    manual: true,
    onSuccess() {
      messageApi.success(`创建成功`);
      props.onSuccess?.();
    },
    onError(e) {
      messageApi.error(e.message);
    },
  });
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={(v) => {
          form.validateFields().then(() => {
            run(v);
          });
        }}
      >
        {context}
        <Form.Item
          name="organization"
          label={<Typography.Title level={5}>组织信息</Typography.Title>}
        >
          <>
            <Form.Item
              label="名称"
              name={['organization', 'name']}
              rules={[{ required: true }]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label="描述"
              name={['organization', 'description']}
              rules={[{ required: true }]}
            >
              <Input maxLength={50} />
            </Form.Item>
          </>
        </Form.Item>
        <Form.Item
          name="owner"
          label={<Typography.Title level={5}>组织所有者</Typography.Title>}
        >
          <>
            <Form.Item
              label="姓名"
              name={['owner', 'name']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="密码"
              name={['owner', 'pwd']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="手机"
              name={['owner', 'mobilePhone']}
              rules={[
                { required: true },
                { pattern: REGEXS.mobilePhone, message: '格式不正确' },
              ]}
            >
              <Input maxLength={100} />
            </Form.Item>
            <Form.Item
              label="邮箱"
              name={['owner', 'email']}
              rules={[
                { required: true },
                { pattern: REGEXS.email, message: '格式不正确' },
              ]}
            >
              <Input maxLength={100} />
            </Form.Item>
          </>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            创建
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export function OrganizationAddButton() {
  return (
    <CmtModalButton
      title="添加组织"
      width={500}
      trigger={({ show }) => {
        return (
          <Button onClick={show}>
            <PlusOutlined /> 添加
          </Button>
        );
      }}
    >
      {({ hide }) => {
        return (
          <OrganizationAddForm
            onSuccess={() => {
              hide();
            }}
          />
        );
      }}
    </CmtModalButton>
  );
}
