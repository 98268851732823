import { Layout, Menu, Spin, Typography } from 'antd';
import { Outlet, RouteObject, useParams } from 'react-router-dom';
import styles from './sub-layout.module.less';
import Sider from 'antd/es/layout/Sider';
import { ItemType } from 'antd/es/menu/hooks/useItems';

const { Content } = Layout;

export function SubLayout(props: {
  menuItems: ItemType[];
  menuAddOn?: React.ReactNode;
}) {
  const openKeys: string[] = props.menuItems.map(
    (x) => x?.key?.toString() || ''
  );

  return (
    <Layout className={styles['layout']}>
      <Sider width={200} className={styles['sidebar']}>
        {<div className={styles['addon']}>{props.menuAddOn}</div>}
        <Menu
          openKeys={openKeys}
          mode={'inline'}
          items={props.menuItems}
          style={{ border: 'none' }}
        />
      </Sider>
      <Layout className={styles['content']}>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
