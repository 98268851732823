import { CloseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message } from 'antd';
import { ConfirmButton } from '../../components/ConfirmButton/confirm-button';
import {
  pauseTaskApi,
  resumeTaskApi,
  stopTaskApi,
} from '../../services/action.service';
import { Task, TaskStatus } from '../../services/task.service';

export function ResumeTaskButton(props: {
  data: Task;
  onSuccess?: () => void;
}) {
  const record = props.data;
  const [messageApi, contextHolder] = message.useMessage();
  const loadingMessageKey = 'resume-message-key';
  const { run, loading } = useRequest(resumeTaskApi, {
    manual: true,
    debounceWait: 200,
    onBefore: () => {
      messageApi.open({
        type: 'loading',
        key: loadingMessageKey,
        content: '正在恢复执行任务，请稍后。。。',
        duration: 0,
      });
    },
    onSuccess: (res) => {
      messageApi.open({
        type: 'success',
        key: loadingMessageKey,
        content: '任务已恢复执行',
        duration: 2,
      });
      props.onSuccess?.();
    },
    onError: (err) => {
      messageApi.open({
        type: 'error',
        key: loadingMessageKey,
        content: err.message,
        duration: 2,
      });
    },
    onFinally: () => {
      // messageApi.destroy(loadingMessageKey);
    },
  });
  return record.status === TaskStatus.Paused ? (
    <>
      {contextHolder}
      <ConfirmButton
        title={'恢复执行任务'}
        content={`确认恢复执行任务【${record.name}】吗? `}
        onOk={() =>
          run({
            taskId: record.id,
            projectId: record.projectId,
            organizationId: record.organizationId,
          })
        }
      >
        {({ trigger }) => (
          <Button type="link" size="small" disabled={loading} onClick={trigger}>
            <PlayCircleOutlined />
          </Button>
        )}
      </ConfirmButton>
    </>
  ) : null;
}
