import { useRequest } from 'ahooks';
import {
  Input,
  Select,
  Button,
  Space,
  message,
  Form,
  Typography,
  Alert,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { REGEXS } from '../../../common/utils/formats';
import { CmtModalButton } from '../../CmtModalButton';
import { GlobalContext } from '../../../context';
import {
  createSingleProjectApi,
  getSingleProjectApi,
  Project,
  ProjectAuthType,
  ProjectPlatforms,
  updateSingleProjectApi,
} from '../../../services/project.service';
import { HowToGetToken } from './how-to-get-token';
import { ProjectAuthTokenInfo } from './project-auth-token-info';
import { FetchPlatformIdButton } from './get-platform-id-button';
import { AuthValueInput } from './AuthValueInput';

export function ProjectForm(props: {
  project?: Project;
  hideSecondaryButton?: boolean;
  onSuccess?: (val: Project | null) => void;
}) {
  const [form] = Form.useForm<any>();
  const [formMode, setFormMode] = useState<'new' | 'update'>('new');
  const [platformType, setPlatformType] = useState<ProjectPlatforms>(
    ProjectPlatforms.GPO
  );
  const [authType, setAuthType] = useState<ProjectAuthType>(
    ProjectAuthType.AccessToken
  );

  const { loading: updating, run: runUpdate } = useRequest(
    updateSingleProjectApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('项目信息更新成功');
        props.onSuccess?.(null);
      },
      onError(e, params) {
        message.error('项目信息更新失败：' + e.message);
      },
    }
  );

  const { loading: creating, run: runCreate } = useRequest(
    createSingleProjectApi,
    {
      manual: true,
      debounceWait: 300,
      onSuccess(data, params) {
        message.success('项目创建成功');
        props.onSuccess?.(params[0]);
      },
      onError(e, params) {
        message.error('项目创建失败：' + e.message);
      },
    }
  );

  useEffect(() => {
    if (props.project) {
      form.setFieldsValue(props.project);
      setAuthType(props.project.authType);
      setPlatformType(props.project.platform);
    }
    if (props.project?.id) {
      setFormMode('update');
    }
  }, [props.project]);
  return (
    <Form
      form={form}
      labelCol={{ span: 3 }}
      labelAlign={'left'}
      disabled={updating || creating}
      onFinish={() => {
        formMode === 'update'
          ? runUpdate(props.project?.id || '', form.getFieldsValue())
          : runCreate(form.getFieldsValue());
      }}
    >
      <br />
      {formMode === 'new' && (
        <>
          <Alert message="新增GPO项目后，请设置网段！"></Alert>
          <br />
        </>
      )}
      <FormItem
        label="组织ID"
        name={'organizationId'}
        hidden
        rules={[{ required: true }]}
      >
        <Input maxLength={50} readOnly bordered={false} />
      </FormItem>
      <FormItem label="名称" name={'name'} rules={[{ required: true }]}>
        <Input maxLength={50} />
      </FormItem>
      <FormItem label="描述" name={'description'} rules={[{ required: true }]}>
        <TextArea maxLength={100} />
      </FormItem>
      <FormItem label="平台类型" name={'platform'} rules={[{ required: true }]}>
        <Select
          onChange={(v) => {
            setPlatformType(v);
          }}
          disabled={formMode === 'update'}
          options={[
            { label: 'GPO', value: ProjectPlatforms.GPO },
            {
              label: '锐竞',
              value: ProjectPlatforms.RJMart,
            },
            {
              label: '喀斯玛',
              value: ProjectPlatforms.CAS,
            },
          ]}
        ></Select>
      </FormItem>
      {platformType === ProjectPlatforms.GPO && (
        // <FormItem label="托管账号" name={'accounts'} rules={[{ required: false }]} help={"请使用格式：账号|手机号|密码， 多个换行"}>
        //   <TextArea placeholder='账号|手机号|密码， 多个换行' style={{height: 100}}></TextArea>
        // </FormItem>
        <FormItem label="GPO站点" name={'gpoType'} rules={[{ required: true }]}>
          <Select
            disabled={formMode === 'update'}
            options={[
              { label: '广东', value: 'GuangDong' },
              { label: '山东', value: 'ShangDong' },
            ]}
          ></Select>
        </FormItem>
      )}
      <FormItem label="验证方式" name={'authType'} rules={[{ required: true }]}>
        <Select
          onChange={(v) => {
            setAuthType(v);
          }}
          disabled={formMode === 'update'}
          options={[
            { label: '用户Token', value: ProjectAuthType.AccessToken },
            { label: 'Open Id', value: ProjectAuthType.OpenId },
            {
              label: 'Cookie',
              value: ProjectAuthType.Cookie,
            },
            {
              label: '账号密码',
              value: ProjectAuthType.Credentials,
              disabled: true,
            },
          ]}
        ></Select>
      </FormItem>
      <FormItem
        label="主身份Token"
        name={'authValue'}
        help={
          <>
            <Typography.Text type="secondary">
              如需更新，请输入新的配置。
            </Typography.Text>
            {props.project?.platform === ProjectPlatforms.GPO && (
              <Typography.Text type="secondary">
                <ProjectAuthTokenInfo
                  organizationId={props.project?.organizationId || ''}
                  projectId={props.project?.id || ''}
                ></ProjectAuthTokenInfo>

                <CmtModalButton
                  title="如何获取密钥"
                  trigger={({ show }) => (
                    <Button type="link" onClick={show}>
                      如何获取密钥?
                    </Button>
                  )}
                >
                  {() => <HowToGetToken />}
                </CmtModalButton>
              </Typography.Text>
            )}
          </>
        }
      >
        <AuthValueInput type={authType} />
      </FormItem>
      <FormItem
        label="平台身份ID"
        name={'supplierGuid'}
        rules={[{ required: false }]}
      >
        <Input
          maxLength={100}
          addonAfter={
            props.project?.id ? (
              <FetchPlatformIdButton
                organizationId={props.project?.organizationId || ''}
                projectId={props.project?.id || ''}
                onSuccess={(v) => {
                  form.setFieldValue('supplierGuid', v);
                }}
              ></FetchPlatformIdButton>
            ) : null
          }
        />
      </FormItem>
      <FormItem
        label="Logo地址"
        name={'logoUrl'}
        rules={[
          { required: false },
          { pattern: REGEXS.url, message: '格式不正确' },
        ]}
      >
        <Input maxLength={200} placeholder="http(s)://" />
      </FormItem>
      <Form.Item>
        <Space>
          <Button
            htmlType="submit"
            loading={updating || creating}
            disabled={updating || creating}
          >
            {formMode === 'new' ? '创建' : '保存'}
          </Button>
          {!props.hideSecondaryButton && (
            <Button type={'text'} onClick={() => props.onSuccess?.(null)}>
              {formMode === 'new' ? '取消' : '返回'}
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
}
