import { Image, Typography } from 'antd';

export function HowToGetToken() {
  return (
    <div>
      <Typography.Paragraph>
        <ol>
          <li>进入控制后台</li>
          <li>
            按<code>F12</code>
          </li>
          <li>
            找到下图的<code>access_token</code>，把后面的字符串复制下来
          </li>
        </ol>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Image src="/how-to.webp" preview={false} />
      </Typography.Paragraph>
    </div>
  );
}
