import { Alert, Space } from 'antd';
import { TwoFactorAuthSetting } from '../profile/2fa-settings';
import styles from './style/index.module.less';

export function TwoFactorForceEnablePage() {
  return (
    <div>
      <div className={styles['login-form-container']}>
        <Space
          direction="vertical"
          size={'large'}
          style={{ maxWidth: 600 }}
          className={styles['login-form']}
        >
          <TwoFactorAuthSetting
            extends={
              <Alert
                type="error"
                message="您所在的组织强制开启了双重身份验证，请按以下步骤启用。"
                showIcon
              />
            }
          />
        </Space>
      </div>
    </div>
  );
}
