import { LoadingOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Descriptions, Skeleton, Spin, Typography } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { useEffect, useState } from 'react';
import {
  getAllGpoCategoriesApi,
  getSingleCategoryApi,
} from '../../services/gpo/category.service';
import {
  queryGoodsCategoryBySupplierAgreements,
  queryGoodsSupplierAgreements,
} from '../../services/gpo/standard-goods.service';
import { Plan } from '../../services/plan.service';
import { getSingleProjectApi } from '../../services/project.service';
import { Action, querySingleActionApi } from '../../services/task.service';

interface Item {
  name: string;
  val: string;
}
export function PlanParamView(props: {
  payload: string;
  projectId: string;
  actionId: string;
}) {
  const [items, setItems] = useState<Item[]>([]);
  const { loading, run: loadActionInfo } = useRequest(querySingleActionApi, {
    debounceWait: 300,
    loadingDelay: 300,
    manual: true,
    onSuccess(data) {
      const params = JSON.parse(props.payload || '{}');
      buildDescriptions(data, params);
    },
  });

  const buildDescriptions = async (
    action: Action,
    savedPayload: { [key: string]: any }
  ) => {
    const properties: { [key: string]: any } =
      action?.payloadSchema?.properties;
    const defKeys = Object.keys(properties);
    const tmpItems: Item[] = [];
    for (let index = 0; index < defKeys.length; index++) {
      const key = defKeys[index];
      const def = properties[key];
      const name = def.title || key;
      let val = savedPayload[key] === undefined ? '--' : savedPayload[key];
      if (savedPayload[key] !== undefined) {
        if (def.anyOf?.length) {
          const option = def.anyOf.find((x: any) => {
            return x.default === val;
          })?.title;
          val = option || val;
        }
        if (def.contentMediaType === 'entity(Project)') {
          const project = await getSingleProjectApi(val);
          val = project.name || val;
        }
        if (
          [
            'SupplierAgreementCategory(Category)',
            'entity(GpoCategory)',
            'entity(SupplierAgreementCategory)',
          ].includes(def.contentMediaType)
        ) {
          const allCategories = await getAllGpoCategoriesApi({
            ...def.defaultParams,
            projectId: props.projectId,
          });
          const category = allCategories.find((x) => x.platformId === val);
          val = category?.platformName || val;
        }
        if (
          [
            'entity(SupplierAgreementCategory[])',
            'entity(GpoCategory[])',
          ].includes(def.contentMediaType)
        ) {
          const allCategories = await getAllGpoCategoriesApi({
            ...def.defaultParams,
            projectId: props.projectId,
          });
          const names = (val as string[]).map?.((x) => {
            return (
              allCategories.find((c) => c.platformId === x)?.platformName || x
            );
          });
          val = names?.join(', ') || val;
        }
        if (def.contentMediaType === 'entity(SupplierAgreement)') {
          const agreements = await queryGoodsSupplierAgreements({
            ...def.defaultParams,
            projectId: props.projectId,
          });
          const ag = agreements.find((x) => x.supplierAgreementGuid === val);
          val = ag?.agreementName || val;
        }
      }

      tmpItems.push({
        name,
        val,
      });
    }
    setItems(tmpItems);
  };

  useEffect(() => {
    loadActionInfo(props.actionId);
  }, []);
  return (
    <div
      style={{ width: 400, minHeight: 50, maxHeight: 300, overflow: 'auto' }}
    >
      {loading && <Spin spinning={true} />}
      {!loading && (
        <Descriptions labelStyle={{ width: 120 }} column={1} bordered>
          {items.map((x) => {
            return (
              <DescriptionsItem label={x.name} key={x.name + x.val}>
                <Typography.Text copyable={!!x.val && x.val !== '--'}>
                  {x.val}
                </Typography.Text>
              </DescriptionsItem>
            );
          })}
        </Descriptions>
      )}
    </div>
  );
}
