import { Navigate, RouteObject } from 'react-router-dom';
import { ENV_CONFIG } from './env-config';
import { MainLayout } from './layouts/main-layout/main-layout';
import { AdminSettingsPage } from './pages/admin';
import { adminRouters } from './pages/admin/routers';
import { Dashboard } from './pages/dashboard';
import { EcsManagementPage } from './pages/ecs';
import { Exception404 } from './pages/exception/404';
import { LoginPage } from './pages/login';
import { TwoFactorForceEnablePage } from './pages/login/two-factor-force-page';
import { OrganizationMgmtPage } from './pages/organization-mgmt';
import { OrganizationGeneralSettingsPage } from './pages/organization-mgmt/organization-general-settings';
import { OrganizationPage } from './pages/organizations';
import { PlanPage } from './pages/plans';
import { UserProfilePage } from './pages/profile';
import { ProjectPage } from './pages/projects';
import { TaskPage } from './pages/tasks';
import { OrganizationUserManagementPage } from './pages/users';
export const routers: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <Exception404 />,
    children: [
      {
        path: 'workspace',
        element: <Dashboard />,
      },
      {
        path: 'organizations',
        element: <OrganizationPage />,
      },
      {
        path: 'organizations/:id',
        element: <OrganizationMgmtPage />,
        children: [
          {
            path: 'projects',
            element: <ProjectPage />,
          },
          {
            path: 'executors',
            element: <EcsManagementPage />,
          },
          {
            path: 'settings',
            element: <OrganizationGeneralSettingsPage />,
          },
          {
            path: 'members',
            element: <OrganizationUserManagementPage />,
          },
          {
            path: '',
            element: <Navigate to={'projects'} replace />,
          },
        ],
      },
      {
        path: 'plans',
        element: <PlanPage />,
      },
      {
        path: 'tasks',
        element: <TaskPage />,
      },
      {
        path: 'me',
        element: <UserProfilePage />,
      },
      {
        path: 'admin',
        element: <AdminSettingsPage />,
        children: adminRouters,
      },
      {
        path: '',
        element: <Navigate to={ENV_CONFIG.DEFAULT_PAGE} replace />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/enable-2fa',
    element: <TwoFactorForceEnablePage />,
  },
];
