import { useRequest } from 'ahooks';
import {
  Button,
  Form,
  QRCode,
  message,
  Result,
  Space,
  Typography,
  Input,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JwtMetaItemKeys } from '../../common/dtos/jwt-token-payload';
import { TwoFactorStatusIcon } from '../../components/TwoFactorStatusIcon';
import { GlobalContext } from '../../context';
import { saveAccessToken } from '../../services/auth.service';
import {
  active2FAApi,
  disable2FAApi,
  setup2FAApi,
} from '../../services/user.service';

enum SettingState {
  NotSet = 1,
  Pending = 2,
  Ready = 3,
}

export function TwoFactorAuthSetting(props: { extends?: React.ReactNode }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [globalContext, setGlobalContext] = useContext(GlobalContext);
  const twoFactorStatus =
    globalContext.userMetaData?.find(
      (x) => x.k === JwtMetaItemKeys.twoFactorEnable
    )?.v === '1';
  const [state, setState] = useState<SettingState>(
    twoFactorStatus ? SettingState.Ready : SettingState.NotSet
  );
  const [showDisableForm, setShowDisableForm] = useState(false);

  const {
    data: qrcodeRes,
    loading: loadingQrcode,
    run: runSetup,
  } = useRequest(setup2FAApi, {
    debounceWait: 300,
    manual: true,
    onSuccess() {
      setState(SettingState.Pending);
    },
    onError(err) {
      message.error(err.message);
      setState(SettingState.NotSet);
    },
  });

  const { loading: settingUp, run: runEnable } = useRequest(active2FAApi, {
    debounceWait: 300,
    manual: true,
    onSuccess() {
      setState(SettingState.Ready);
      message.success('启用成功，请重新登录');
      saveAccessToken('');
      navigate('/login');
    },
    onError(err) {
      message.error(err.message);
    },
    onFinally() {
      form.resetFields();
    },
  });

  const { loading: disabling, run: runDisable } = useRequest(disable2FAApi, {
    debounceWait: 300,
    manual: true,
    onSuccess() {
      setState(SettingState.NotSet);
      message.success('停用成功，请重新登录');
      saveAccessToken('');
      navigate('/login');
    },
    onError(err) {
      message.error(err.message);
    },
    onFinally() {
      form.resetFields();
    },
  });

  return (
    <Result
      status={state === SettingState.Ready ? 'success' : 'info'}
      style={{ width: '80%', margin: '0 auto' }}
      icon={<TwoFactorStatusIcon active={state === SettingState.Ready} />}
      subTitle={
        <>
          {state !== SettingState.Pending && (
            <Space direction="vertical" style={{ width: '100%' }}>
              {props?.extends}
              <Typography.Paragraph>
                双重身份验证 (2FA) 是登录网站或应用时使用的额外保护层。启用 2FA
                时，必须使用您的用户名和密码登录，并提供另一种只有您知道或可以访问的身份验证形式。为确保帐户安全，强烈建议启用
                2FA。
              </Typography.Paragraph>
            </Space>
          )}
          {state === SettingState.Pending && (
            <Typography.Paragraph>
              请使用验证器扫描下方二维码
            </Typography.Paragraph>
          )}
        </>
      }
      extra={
        <Space direction="vertical" style={{ width: '100%' }}>
          {state === SettingState.NotSet && (
            <Button
              type="primary"
              loading={loadingQrcode}
              onClick={() => {
                runSetup();
              }}
            >
              启用双重身份验证
            </Button>
          )}
          {state === SettingState.Ready && (
            <div style={{ width: 200, margin: '0 auto' }}>
              {!showDisableForm && (
                <Button
                  type="primary"
                  danger
                  htmlType="submit"
                  onClick={() => setShowDisableForm(true)}
                >
                  停用2FA
                </Button>
              )}
              {showDisableForm && (
                <Form
                  form={form}
                  onFinish={() => {
                    runDisable(form.getFieldsValue());
                  }}
                >
                  <FormItem
                    name="code"
                    rules={[
                      { required: true, message: '请输入验证器上的验证码' },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="验证码"
                      style={{ width: '100%', textAlign: 'center' }}
                    />
                  </FormItem>
                  <FormItem>
                    <Button
                      type="primary"
                      loading={disabling}
                      disabled={disabling}
                      htmlType="submit"
                    >
                      停用2FA
                    </Button>
                  </FormItem>
                </Form>
              )}
            </div>
          )}
          {state === SettingState.Pending && (
            <Space direction="vertical" style={{ width: 200 }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <QRCode value={qrcodeRes?.otpauthUrl || ''}></QRCode>
              </div>
              <Form
                form={form}
                onFinish={() => {
                  runEnable(form.getFieldsValue());
                }}
              >
                <FormItem
                  name="code"
                  rules={[
                    { required: true, message: '' },
                    { pattern: /\d{6}/, message: '' },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="验证码"
                    style={{ width: '100%', textAlign: 'center' }}
                  />
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    loading={settingUp}
                    disabled={settingUp}
                    htmlType="submit"
                  >
                    确认启用2FA
                  </Button>
                </FormItem>
              </Form>
            </Space>
          )}
        </Space>
      }
    ></Result>
  );
}
