export const JwtMetaItemKeys = {
  email: '@',
  userName: 'un',
  userId: 'uid',
  organizationId: 'oid',
  mobile: 'tel',
  organizationName: 'on',
  actions: 'act',
  twoFactorEnable: '2fa',
  forceTwoFactor: 'force2fa',
  twoFactorVerified: '2fa-verified',
};
export interface JwtMetaItem {
  k: string;
  v: string;
}
export interface JwtTokenPayload {
  /**
   * 用户ID
   */
  sub: string;
  meta: JwtMetaItem[];
}
